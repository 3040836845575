/* pool not initialized */

.pool_not_initialialized_container {
    width: 100%;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
}
.pool_init_bg {
    width: 480px;
    height: 204px;
    border-radius: var(--border-radius);
    padding: 1px;
}

.hide_chart {
    cursor: pointer;
}

.pool_not_initialialized_content {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.pool_not_init_inner {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    gap: 8px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.pool_not_initialialized_content h2 {
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    color: var(--text1);
    font-weight: 300;
}
.pool_not_initialialized_content h3 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text3);
    font-weight: 300;
}

.initialize_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 12px 3rem;
    font-size: var(--header-size);
    line-height: var(--header1-lh);

    height: 48px;
    cursor: pointer;
    text-align: center;

    background: transparent;
    text-transform: capitalize;

    color: var(--text1);
    border-radius: var(--border-radius);
    transition: all var(--animation-speed) ease-out;

    background: linear-gradient(
        90deg,
        rgba(115, 113, 252, 0) 0%,
        rgba(115, 113, 252, 0) 71.21%,
        var(--accent1) 100%
    );
    text-decoration: none;
    border: 1px solid var(--accent1);
}

.initialize_link:hover {
    color: var(--dark1);
    background: linear-gradient(to left, var(--accent1) 34%, var(--accent5) 65%)
        right;
    background-size: 300% 100%;
}
.disabled_button {
    pointer-events: none;
    opacity: 0.4;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.isFetching svg {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}
