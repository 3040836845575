.slippage_tolerance_container {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 8px;
}
.slippage_title {
    display: flex;

    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    margin-bottom: 0.2em;
}
.slippage_box {
    background-color: var(--dark2);
    border-radius: var(--border-radius);
    /* border: 1px solid var(--text3); */
    height: 33px;
}
.slippage_content {
    width: 100%;
    height: 100%;
    display: flex;

    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0 8px;
}
.slippage_content input {
    height: 100%;
    border: 0px;
    border-radius: var(--border-radius);
    background: none;
    font-size: var(--header-size);
    width: auto;
    outline: none;
    color: var(--text1);
    text-align: left;
}

.slippage_content input::placeholder {
    color: var(--text2);
}

.input_container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.slippage_content button {
    margin: 0 2px;
}
