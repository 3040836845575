.tx_details_container {
    width: 100%;
    height: 100%;
    padding: 1rem;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}

.info_content {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
    backdrop-filter: blur(10px);

    padding: 1rem;
}

.link_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: default;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
