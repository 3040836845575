.remove_position_info {
    background: var(--dark2);
    border-radius: var(--border-radius);
    padding: 0 8px;

    display: flex;
    flex-direction: column;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.info_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    gap: 8px;

    border-bottom: 1px solid var(--dark1);
}
.token_price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.125em;
}

.token_price img {
    width: 15px;
}

.align_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.network_fee_container {
    border: 1px solid var(--dark2);
    border-radius: var(--border-radius);
    padding: 8px 16px;
    margin-top: 8px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
