.overlay_wrapper,
.overlay_wrapper_active {
    padding: 1rem;
    /* width: 100%; */
    /* height: 400px; */
    /* position: absolute; */
    position: fixed;
    background: var(--dark2);
    width: 200px;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.overlay_wrapper_active:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
}

.overlay_wrapper {
    opacity: 0;
    pointer-events: none;
}

.overlay_wrapper_active {
    opacity: 1;

    pointer-events: auto;
    z-index: 99999999999;
}

.overlay_wrapper_active h3 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}
.overlay_wrapper_active p {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text1);
}
