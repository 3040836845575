.game_container {
    max-width: 600px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.game_container button {
    max-width: 200px;
    margin: 5px 0;
}

.card_grid {
    margin: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
