.title {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: var(--text1);
}

.settings_icon {
    margin: 0.5rem 0 0.5rem 1rem;
    cursor: pointer;
    /* height: 30px;
    width: 30px; */
}

.close_icon {
    cursor: pointer;
    margin: 0.5rem 1rem 0.5rem 0;
    height: 30px;
    width: 30px;
}
