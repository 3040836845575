.circle {
    display: block;

    /* border: 0.5rem solid #e9e9e9;
    border-top: 0.5rem solid var(--accent4); */
    border-radius: 50%;
    /* position: absolute; */
    box-sizing: border-box;
    top: 0;
    left: 0;
}

.circle_completed {
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
}

.circle_failed {
    /* min-width: 7rem;
    min-height: 7rem; */
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;

    border-radius: 50%;

    border: 1px solid var(--negative);
}

.circle_failed p {
    font-weight: 300;

    font-size: var(--body-size);
    line-height: var(--body-lh);
}
