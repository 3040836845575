/* I do not want to change the classnames of the file to CSS modules since I am aware those classnames are being used for the graph data */

.chart-fee,
.chart-tvl,
#chart-volume {
    transition: all 600ms ease-in-out;
}

.main_layout {
    transition: all 600ms ease-in-out;
    width: 100%;
    color: var(--text2);
}

.domain,
.tick > path,
.gridline-y,
.gridline-x {
    stroke: var(--text2);
    stroke-opacity: 0.1;
}

.candlestick .down {
    background-color: var(--accent1);
}

hr {
    display: block;
    height: 0.5px;
    border: 0;
    border-top: 0.5px solid var(--dark3);
}

.img_shimmer {
    display: inline-block;
    -webkit-mask: linear-gradient(
            -60deg,
            black 30%,
            rgba(119, 117, 117, 0.333),
            black 70%
        )
        right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.2s infinite;
    filter: blur(8px);
}
@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

.animatedImg_container {
    width: 100%;
    height: 100%;
    cursor: progress;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: relative; */

    /* 
background:     linear-gradient(
    rgba(142, 118, 186, 0.3), 
    rgba(172, 152, 210, 0.3)
  ),

  url('../../../assets/images/charts/candlestick.png') no-repeat center center ; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover; */

    /* filter: blur(4px) */
}

.animatedImg_container img {
    object-fit: contain;
}
.fetching_text {
    z-index: 9;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(10px); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    color: var(--text1);
}
.animatedImg_container img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}
