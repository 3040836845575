@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500&display=swap);
/* For simple theme change(special occasion), change portfolio and swap background. No need for a different color scheme*/
:root {
    --font-logo: 'Lexend Exa', sans-serif;
    --font-family: 'Lexend Deca', sans-serif;
    --roboto: 'Roboto Mono', monospace;
    --mono: monospace, 'Lexend Deca' sans-serif;

    /* font */
    --title-ts: 0px 0px 150px rgba(70, 183, 219, 0.5),
        0px 0px 100px rgba(70, 183, 219, 0.5),
        0px 0px 30px rgba(70, 183, 219, 0.5),
        0px 0px 20px rgba(70, 183, 219, 0.5),
        0px 0px 10px rgba(70, 183, 219, 0.5),
        0px 0px 3px rgba(70, 183, 219, 0.5);

    --header1-size: 24px;
    --header1-lh: 25px;

    --header2-size: 18px;
    --header2-lh: 22px;

    --header-size: 16px;
    --body-size: 12px;
    --body-lh: 15px;

    --mini-size: 10px;
    --mini-lh: 12px;

    /* colors */
    --blur-bg: rgba(14, 19, 26, 0.5);

    --text1: #f0f0f8;
    --text2: #8b98a5;
    --text3: #61646f;

    --dark1: #06060C;
    --dark2: #12121A;
    --dark3: #1E1E24;
    --dark4: #0E0E14;

    --accent1: #7371fc;
    --accent2: #e480ff;
    --accent3: #5ffff2;
    --accent4: #71b5fc;
    --accent5: #cdc1ff;

    --positive: #15be6f;
    --negative: #f6385b;

    --other-green: #41d18e;
    --other-red: #e75670;

    /* border */
    --color-border: 1px solid var(--accent1);
    --border: var(--dark2);
    --border-radius: 4px;
    --swap-border: var(--title-gradient);
    --grey-light-border: 1px solid var(--text2);
    --navbar-border: transparent;
    --dark-border: 0.2px solid #010101;

    /* transitions */
    --transition: all var(--animation-speed) ease-in-out;

    /* animations */
    --animation-speed: 300ms;

    /* sizes */
    --footer-height: 24px;

    /* gradients, shadows */
    --shimmer: linear-gradient(to right, #141e30, #243b55);
    --gradient-box-shadow: 0px 0px 20px 0px rgba(115, 113, 252, 0.25) inset;

    --title-gradient: linear-gradient(
        90deg,
        var(--accent1) 0%,
        var(--accent5) 49.48%,
        var(--accent1) 100%
    );
    --primary-gradient: linear-gradient(
        to right top,
        #7371fc,
        #887ffd,
        #9a8efe,
        #ab9dfe,
        #baacff,
        #baacff,
        #baacff,
        #baacff,
        #ab9dfe,
        #9a8efe,
        #887ffd,
        #7371fc
    );
    --chart-gradient: linear-gradient(to right, #0f1623, #152332);
    --dark-gradient: linear-gradient(
        90deg,
        #1c1c41 0%,
        #645e7e 49.48%,
        #19193b 100%
    );
    --highlight-gradient: linear-gradient(
        270deg,
        #5a58c7,
        #b2a8dd,
        #4947a1,
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0)
    );
    --red-gradient: linear-gradient(
        90deg,
        #be4515 32.38%,
        #de5a2e 51.39%,
        #be5315 68.17%
    );
    --apr-gradient-primary: linear-gradient(
        90deg,
        #15be6f 32.38%,
        #8fe9bf 51.39%,
        #15be6f 68.17%
    );
    --apr-gradient-secondary: linear-gradient(
        90deg,
        #c7d80c 0%,
        #e0ea74 49.48%,
        #c7d80c 100%
    );

    --swap-box-shadow: none;
    --dark-box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    --dark1-box-shadow: 2px 2px 10px 0px var(--dark1);
    --trade-box-shadow: 0 0 3px white, 0 0 5px white, 0 0 10px white,
        0 0 30px #0ba9ca, 0 0 50px #0ba9ca, 0 0 80px #0ba9ca;
    --glow-box-shadow: 0px 0px 100px rgba(205, 193, 255, 0.3),
        0px 0px 60px rgba(205, 193, 255, 0.3),
        0px 0px 20px rgba(205, 193, 255, 0.3),
        0px 0px 12px rgba(205, 193, 255, 0.3),
        0px 0px 6px rgba(205, 193, 255, 0.3),
        0px 0px 3px rgba(205, 193, 255, 0.3);
    --glow-light-box-shadow: 0px 0px 36px rgba(205, 193, 255, 0.2),
        0px 0px 21px rgba(205, 193, 255, 0.2),
        0px 0px 12px rgba(205, 193, 255, 0.2),
        0px 0px 7px rgba(205, 193, 255, 0.2), 0px 0px 4px var(--accent5),
        0px 0px 2px rgba(205, 193, 255, 0.2);
}
*::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text2);
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--text2);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--text2);
}

[data-theme='light'] {
    --portfolio-banner: linear-gradient(to right, #f0f2f0, #000c40);

    /* colors */
    --blur-bg: #ebebed;

    --text1: black;
    --text2: #19222d;
    --text3: #0e131a;

    --dark1: #e6eaf2;
    --dark2: #f6f6f7;
    --dark3: #eff1f4;

    --shimmer: linear-gradient(to right, #ece9e6, white);
    --chart-gradient: linear-gradient(to right, #d8d4d1, white);
    --dark-gradient: linear-gradient(
        90deg,
        #9494d1 0%,
        #645e7e 49.48%,
        #7d7db1 100%
    );

    --swap-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
        0 3px 6px rgba(0, 0, 0, 0.23);
    --swap-border: none;
    --navbar-border: #cacbcd;
    --dark-border: 0.2px solid #fefefe;
    --border: #cacbcd;
    --select: #acb6c4;
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]),
a:not([className]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img,
picture {
    max-width: 100%;
} */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

iframe {
    display: none;
}

.gradient_text {
    background: var(--title-gradient);
    font-family: var(--font-logo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* order table */

.color_white {
    color: var(--text1);
}

.base_color {
    color: var(--text1);
}

.username_base_color {
    color: var(--text1);
    font-family: var(--roboto);
}

.primary_color {
    color: var(--accent1);
}

.order_sell {
    color: var(--accent1);
}
.owned_tx_contrast {
    color: var(--accent2);
    font-weight: 300;
}

.order_buy {
    color: var(--accent5);
}

/* transactions */
.add_style {
    color: var(--accent3);
    border-color: var(--accent3) !important;
}
.claim_style,
.remove_style {
    color: var(--accent4);
    border-color: var(--accent4) !important;
}
.buy_style {
    color: var(--accent5);
    border-color: var(--accent5) !important;
}
.sell_style {
    color: var(--accent1);
    border-color: var(--accent1) !important;
}

/* apy */

.apy_positive {
    color: var(--text1);
}

.apy_negative {
    color: var(--text1);
}

/* sort */

.active_sort {
    background: var(--dark2);
}



.hide_scrollvar::-webkit-scrollbar {
    display: none;
}

.emoji-picker-react .emoji-group:before,
.emoji-picker-react input.emoji-search {
    color: var(--text2) !important;
    background: var(--dark2) !important;
}

aside.emoji-picker-react {
    background: var(--dark2) !important;
    box-shadow: 0 5px 10px var(--dark1) !important;
    border: 1px solid var(--border) !important;
}

.emoji-picker-react .emoji-categories button {
    filter: invert(100%);
}

.MuiTooltip-popper {
    z-index: 9999999 !important;
}

.introjs-tooltip {
    background: var(--dark2) !important;
    /* background: white !important; */
}
.introjs-tooltip-header {
    color: var(--text1);
    /* color: black; */
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
}
.introjs-tooltip-title {
    color: var(--text2) !important;
    /* color: black !important; */
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.introjs-button {
    background: var(--dark2) !important;

    outline: none !important;
    border: none !important;
    color: var(--text2) !important;
    text-shadow: none !important;
    transition: all var(--animation-speed) ease-in-out !important;
    padding: 0 1rem !important;
}
.introjs-tooltipbuttons a {
    /* font-size: 1.2rem; */
    color: var(--accent5);
    /* font-weight: 700; */
}

.introjs-button:hover {
    color: var(--text1) !important;
    border: none !important;
    outline: none !important;
}
.introjs-tooltipbuttons {
    position: fixed;
    right: 51.14px;
    bottom: 48px;
    padding: 4px 16px !important;

    box-shadow: var(--glow-box-shadow);
    border-radius: var(--border-radius);
}

/* .MuiPagination-root{
        
        background: red !important;
    } */
.MuiPagination-ul {
    background: var(--dark2);
    border-radius: var(--border-radius) !important;
    flex-wrap: nowrap !important;
}

.MuiPaginationItem-root {
    color: var(--text2) !important;
    outline: 1px solid transparent !important;
}

.MuiPaginationItem-root:hover {
    color: var(--text1) !important;
}
.Mui-selected.MuiPaginationItem-root {
    color: var(--text1) !important;
    background: transparent !important;
}

.MuiPaginationItem-root {
    display: inline-flex !important;
}

.css-1idz92c-MuiCircularProgress-svg {
    color: var(--title-gradient) !important;
}
.css-9tmuzo-MuiStepper-root{
    grid-gap: 4px !important;
    gap: 4px !important;
    font-size: 18px;

  
}

.css-14sza3e-MuiStepLabel-root {
    padding: 0 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: var(--accent1) !important
}


.icon_hover:hover {
    color: var(--accent1) !important;
    cursor: pointer;
}
#current_row_scroll {
    height: 100%; 
    box-sizing: border-box; 
}

@media (max-width: 500px) {
    #current_row_scroll {
        overflow-y: scroll;
        height: 45dvh;
    }
    .css-th9gsg-MuiPaper-root-MuiAlert-root{
        margin: 0 auto !important;
        text-wrap:wrap !important;
        word-wrap: break-word !important;
        max-width: 330px !important;
    }
  }
@media only screen and (min-width: 1280px) {
    .top-pools-analytics-table td,
    .top-pools-analytics-table th {
        min-width: 180px;
    }
}
@media only screen and (max-width: 768px) {
   .mobile_auto_height{
    height: auto !important;
   }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
    font-weight: 300;

    /* outline: 1px solid red; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar-track {
    background: transparent; /* opacity: 0; should do the thing either */
}
body::-webkit-scrollbar-thumb {
    background: linear-gradient(
        0deg,
        var(--accent1) 0%,
        var(--accent5) 49.48%,
        var(--accent1) 100%
    );

    box-shadow: 0px 0px 0px 100000vh black;
}
body {
    background: var(--dark1);
}

html {
    background: var(--dark1);
    scrollbar-width: none;
    color: var(--text1);
    height: 100%;
}

svg {
    color: var(--text2);
    /* transition: var(--transition); */
}

svg:hover {
    color: var(--accent1);
    cursor: pointer;
}

button:hover > svg {
    color: var(--accent1);
    cursor: pointer;
}

button:focus > svg {
    color: var(--accent1);
    cursor: pointer;
}

/* button,
svg,
a {
    transition: var(--transition);
} */

a {
    color: unset;
    text-decoration: none;
    /* position: relative;  */
}

input:disabled {
    color: var(--text3);
    cursor: default;
}

body {
    overflow-x: hidden;

    min-height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    /* padding: 0 1rem; */
}

footer {
    /* To make page 100vh, turn off the footer position sticky and turn on absolute then style the footer based on route. */
    /* position: absolute;
  position: sticky;
  right: 0;
 bottom: 0; */
    margin-top: auto;
}

.swap-body {
    height: calc(100vh - (var(--footer-height) + 2rem));
}

h3:focus-visible,
h2:focus-visible,
h1:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible {
    text-decoration: underline;
    border: none;
    outline: none;
    -webkit-text-decoration-color: var(--text1);
            text-decoration-color: var(--text1);
}
.footer_container {
    color: white;

    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 0;
    bottom: 0px;
    z-index: 999;
}

.primary_apr {
    background: var(--apr-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* glow/positive */

    text-shadow: 0px 0px 10px rgba(21, 190, 111, 0.75);
}

.secondary_apr {
    background: var(--apr-gradient-secondary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.sr-only {
    position: absolute;
    width: 1px;
    aspect-ratio: 1;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.blur_app {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;

    background: rgba(1, 0, 16, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    transition: var(--transition);
}
/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--animation-speed) ease;
}

.menu-secondary-enter {
    position: absolute;
    transform: translateX(110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-secondary-exit {
    position: absolute;
}
.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--animation-speed) ease;
}

.menu-tertiary-enter {
    position: absolute;
    transform: translateX(110%);
}
.menu-tertiary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-tertiary-exit {
    position: absolute;
}
.menu-tertiary-exit-active {
    transform: translateX(110%);
    transition: all var(--animation-speed) ease;
}

.hide_sidebar {
    display: grid;
    grid-template-columns: 100%;
}

.tutorial_navigation_container {
    position: absolute;
    bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -webkit-appearance: textfield;
            appearance: textfield;
    -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {
    .app_blur {
        width: 100vw;
        height: 100vh;
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);

        position: fixed;
        top: 56px;
        bottom: 64px;
        z-index: 2;
    }
    .swap-body {
        /* max-height: 86.5vh; */
        overflow-y: hidden;

        max-height: calc(100vh - 7.5rem);
    }
}

@media only screen and (min-width: 1280px) {
    .sidebar_content_layout {
        transition: var(--transition);
        display: grid;
        grid-template-columns: 296px auto;
        overflow-y: hidden;
    }
    .sidebar_content_layout_close {
        transition: var(--transition);

        display: grid;
        grid-template-columns: 32px auto;
        overflow-y: hidden;
    }

    .content-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;

        background: var(--dark1);
    }
    .content-container-trade {
        overflow: hidden;

        background: var(--dark1);
    }
}

.LogoutButton_logout_button__39ebY {
    cursor: pointer;
    width: 100%;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    border: none;
    outline: none;
    transition: var(--transition);
    background: var(--accent1);
    padding: 6px 8px;
    grid-gap: 4px;
    gap: 4px;
    border-radius: var(--border-radius);
    border: solid 1px transparent;
}

.LogoutButton_logout_button__39ebY:hover,
.LogoutButton_logout_button__39ebY:focus-visible {
    color: var(--accent1);
    background: var(--dark2);
    border: solid 1px var(--accent1);
}

.Spinner_container__1TSw- {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Spinner_loader__251jd {
    position: relative;

    border-radius: 50%;
    background: linear-gradient(
        45deg,
        transparent,
        transparent 40%,
        var(--accent1)
    );

    animation: Spinner_animate__1O7op 1s linear infinite;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Spinner_background__1FjH2 {
    position: absolute;

    border-radius: 50%;
    z-index: 2;
}

.Spinner_overlay__2uiZt {
    background: conic-gradient(
        from 90deg at 50% 50%,
        rgba(90, 88, 204, 0) 0deg,
        rgba(115, 113, 252, 0) 0.04deg,
        var(--accent1) 360deg
    );

    position: absolute;

    border-radius: 50%;
    z-index: 1;
    filter: blur(30px);
}

@keyframes Spinner_animate__1O7op {
    0% {
        transform: rotate(0deg);
        filter: hue-rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        filter: hue-rotate(10deg);
    }
}

.TokenIcon_token_icon__3jmIQ {
    -webkit-clip-path: circle();
            clip-path: circle();
}

.TokenIcon_token_logo_wrapper__2iFOE {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.NoTokenIcon_no_token_icon__3KSSS {
    padding: 1.5px;

    background: var(--title-gradient);

    border-radius: 50%;
}
.NoTokenIcon_no_token_icon__3KSSS p {
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 50%;
    color: var(--text1);
    font-size: 18;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.TokenSelect_main_container__3Y94p {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: var(--transition);
    background: transparent;
    outline: none;
    border: none;
    border-radius: var(--border-radius);
    padding: 0 8px;
}

.TokenSelect_main_container__3Y94p:hover,
.TokenSelect_main_container__3Y94p:focus-visible {
    background: var(--dark2);

    cursor: pointer;
}

.TokenSelect_main_container__3Y94p:focus-visible {
    border-left: 1px solid var(--text1);
}

.TokenSelect_modal_content__1MZCX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 5px;
    border-radius: var(--border-radius);
    transition: var(--transition);
}

.TokenSelect_left_side_container__uqVe0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.TokenSelect_star_icon__FRgIe,
.TokenSelect_close_icon__2FEaJ {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);
}

.TokenSelect_close_icon_svg__9EQdV:hover {
    color: var(--accent5);
}

.TokenSelect_modal_tokens_info__3bOz7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: var(--transition);
    cursor: pointer;
    grid-gap: 1rem;
    gap: 1rem;
}

.TokenSelect_star_svg__3e_vS {
    cursor: pointer;
    transition: var(--transition);
}

.TokenSelect_star_svg__3e_vS:hover {
    fill: var(--text2);
    cursor: pointer;
}

.TokenSelect_modal_tokens_amount__2wxzQ {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;
}

.TokenSelect_modal_token_symbol__2_OWC,
.TokenSelect_modal_tokens_amount__2wxzQ {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    /* font-weight: 400; */
}
.TokenSelect_modal_token_name__26YWF {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.TokenSelect_delete_container__3tGmD {
    padding: 0 10px;
    position: absolute;
    border-radius: var(--border-radius);
    bottom: 0;
    right: 0;
    background-color: var(--dark2);
    overflow: hidden;
    height: 100%;
    transition: var(--transition);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--body-size);
    font-size: 13px;
    line-height: var(--body-lh);
}

.TokenSelect_delete_active__1SjaA {
    left: 100%;
    width: 0;
}

.TokenSelect_delete_inactive__3CKEi {
    width: 100%;
    left: 0;
}

.TokenSelect_toggle_container__Attc2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.TokenSelect_liqtype_buttons_container__2nuVT {
    font-size: 0;
}

.TokenSelect_liqtype_buttons_container__2nuVT button {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    cursor: pointer;
    transition: var(--transition);
    padding: 5px 12px;
    border: none;
    border: 1px solid var(--accent5);
    outline: none;
}

.TokenSelect_liqtype_buttons_container__2nuVT button:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.TokenSelect_liqtype_buttons_container__2nuVT button:nth-child(2) {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.TokenSelect_liqtype_buttons_container__2nuVT button:hover {
    color: var(--text2);
}

.TokenSelect_name_container__2_ppt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.TokenSelect_active_button__24L-y {
    color: rgb(0, 0, 0);
    background: var(--title-gradient);
}

.TokenSelect_non_active_button__2tEGb {
    color: white;
    background-color: transparent;
}

.TokenSelect_confirm__3ytB6 {
    cursor: pointer;
    transition: all var(--animation-speed) ease;
}

.TokenSelect_primary_style__1xMS_ {
    color: var(--accent5);
}

.TokenSelect_danger_style__wARtZ {
    color: var(--negative);
}

.TokenSelect_confirm__3ytB6:hover {
    transform: scale(1.03);
}

.TokenSelect_token_list_data__23G08 {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    width: 100%;
    text-align: end;
}

.SoloTokenSelectModal_container__2t-vG {
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: var(--border-radius);
    
}

.SoloTokenSelectModal_no_modal_container__1qov2{
   
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: var(--dark1);
    border-radius: var(--border-radius);

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.SoloTokenSelectModal_container__2t-vG h2, .SoloTokenSelectModal_no_modal_container__1qov2 h2 {
    font-size: var(--body-size);
    line-height: var(--header2-lh);
    color: var(--text2);
}

.SoloTokenSelectModal_container__2t-vG input, .SoloTokenSelectModal_no_modal_container__1qov2 input {
    width: 100%;
    height: 30px;
    background: var(--dark2);
    outline: none;
    border: none;
    padding: 0 4px;
    margin-bottom: 8px;
    border-radius: var(--border-radius);
}

.SoloTokenSelectModal_scrollable_container__18s_J {
    max-height: 70vh;
    overflow-y: scroll;
}

.SoloTokenSelectModal_scrollable_container__18s_J::-webkit-scrollbar {
    width: 3px;
}

.SoloTokenSelectModal_token_not_found__3M-8S {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SoloTokenSelectModal_input_control_container__3JbGm {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.SoloTokenSelectModal_input_control_container__3JbGm button {
    background: transparent;
    padding: 6px 0;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    border: none;
    outline: none;
    cursor: pointer;
    transition: var(--transition);
}
.SoloTokenSelectModal_clearButton__1iLvh {
    transform: translateY(-16%);
}

.SoloTokenSelectModal_input_control_container__3JbGm button:hover {
    color: var(--text1);
}

.SoloTokenSelectModal_loader__1qvA0 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SoloTokenSelectModal_weth_warning__2tw5H {
    display: flex;
    flex-direction: row;
}

.SoloTokenSelectModal_weth_warning__2tw5H > button {
    min-width: 20%;
    font-size: 30px;
}

.SoloTokenSelectModal_weth_text__1S4It {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    text-align: center;
    color: var(--text1);
    padding: 1rem;
}

.SoloTokenSelectModal_header__3mp5o{
    height: 41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    font-weight: 200;
    color: var(--text1);
}
@media only screen and (min-width: 768px) {
    .SoloTokenSelectModal_container__2t-vG {
        width: 448px;
    }

    .SoloTokenSelectModal_no_modal_container__1qov2{
        width: 772px;
    }
}

.DividerDark_divider_container__7kdhm {
    background: transparent;
    height: 8px;
    /* margin: 4px 0; */
}

.DividerDark_divider__3Q87v {
    background: var(--border);
    width: 100%;
    /* margin: 0.5rem 0; */
    height: 1px;
}

.DividerDark_add_margin_top__13qvn {
    margin-top: 4px;
}

.DividerDark_add_margin_bottom__3KLBK {
    margin-bottom: 4px;
}

.DividerDark_change_color__30bJe {
    background: var(--dark3);
    height: 1px;
}

.Modal_outside_modal__2fDkF {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;

    background: rgba(1, 0, 16, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: var(--border-radius);
    transition: var(--transition);
}

.Modal_modal_body__QbhVU {
   
    border: solid 1px transparent;
    background-image: linear-gradient(
            rgba(189, 27, 27, 0),
            rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, var(--accent1), var(--accent1), var(--accent1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--dark1) inset;
    transition: box-shadow var(--animation-speed) ease-in-out;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
    min-height: 200px;
    min-width: 300px;
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;
    /* max-height: 80vh; */
    color: var(--text1);

    /* min-height: 80vh; */
}

.Modal_box_shadow__3UYex{
    box-shadow: var(--gradient-box-shadow);
}

.Modal_no_background_modal__1MuJJ {
    background: transparent;
}

.Modal_modal_header__Nrmg6 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    text-transform: capitalize;
    font-size: 14px;
    color: var(--text1);
    padding: 1rem;
}

.Modal_modal_header__Nrmg6 h2 {
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    font-weight: 300;
    color: var(--text1);
    padding-left: 27px;
}

.Modal_modal_content__3bUjv {
    /* padding: 20px; */
   
    border-radius: var(--border-radius);
   
   
    

    /* min-height: 250px; */
}

.Modal_modal_header__Nrmg6 svg:hover {
    color: var(--text1);
}

.Modal_close_button__1VINt {
    cursor: pointer !important;
}

@media only screen and (min-width: 500px) {
    .Modal_modal_body__QbhVU {
        min-width: 400px;
       
    }
}

.WarningBox_warning_box__3mz2T {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: solid 1px var(--other-red);
    border-radius: var(--border-radius);
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(231, 86, 112, 0.1) 0%,
        rgba(231, 86, 112, 0.1) 100%
    );
    color: var(--other-red);

    font-weight: 200;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;
}

.WarningBox_warning_box__3mz2T div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.WarningBox_warning_box__3mz2T p {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    padding-top: 6px;
}

.WarningBox_text_only__9ICVE {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    vertical-align: middle;
}
.WarningBox_text_only__9ICVE p {
    color: var(--other-red);
    font-size: var(--header-size);
    line-height: var(--body-lh);
    text-align: center;
    cursor: default;
}

.WarningBox_warning_box__3mz2T button,
.WarningBox_text_only__9ICVE button {
    cursor: pointer;
    background: var(--other-red);
    color: var(--dark1);
    outline: none;
    border: none;
    border-radius: var(--border-radius);
    padding: 6px 8px;
    border: 1px solid transparent;
    white-space: nowrap;
}

.WarningBox_warning_box__3mz2T button:hover,
.WarningBox_text_only__9ICVE button:hover {
    background-color: transparent;
    border: 1px solid var(--other-red);
    transition: var(--transition);
    color: var(--text1);
}

.WarningBox_warning_box__3mz2T svg,
.WarningBox_text_only__9ICVE svg {
    pointer-events: none !important;

    display: flex;
    justify-content: center;
    align-items: center;
}



.SoloTokenSelect_container__2IuQu{
   z-index: 2;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: var(--dark1);
    border-radius: var(--border-radius);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
 
    height: 100%;
    z-index: 9999;

   
}

.SoloTokenSelect_container__2IuQu h2 {
    font-size: var(--body-size);
    line-height: var(--header2-lh);
    color: var(--text2);
}

.SoloTokenSelect_container__2IuQu input {
    width: 100%;
    height: 30px;
    background: var(--dark2);
    outline: none;
    border: none;
    padding: 0 4px;
    margin-bottom: 8px;
    border-radius: 4px;
}

.SoloTokenSelect_scrollable_container__24mt8 {
    max-height: 70vh;
    overflow-y: scroll;
}

.SoloTokenSelect_scrollable_container__24mt8::-webkit-scrollbar {
    width: 3px;
}

.SoloTokenSelect_token_not_found__2gDY5 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SoloTokenSelect_input_control_container__22tko {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.SoloTokenSelect_input_control_container__22tko button {
    background: transparent;
    padding: 6px 0;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    border: none;
    outline: none;
    cursor: pointer;
    transition: all var(--animation-speed) ease-in-out;
}
.SoloTokenSelect_clearButton__2zZ2W {
    transform: translateY(-16%);
}

.SoloTokenSelect_input_control_container__22tko button:hover {
    color: var(--text1);
}

.SoloTokenSelect_loader__3vwpt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SoloTokenSelect_weth_warning__2dB-g {
    display: flex;
    flex-direction: row;
}

.SoloTokenSelect_weth_warning__2dB-g > button {
    min-width: 20%;
    font-size: 30px;
}

.SoloTokenSelect_weth_text__1yTVt {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    text-align: center;
    color: var(--text1);
    padding: 1rem;
}

.SoloTokenSelect_header__30x3Y, .SoloTokenSelect_container__2IuQu header{
    height: 41px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    font-weight: 200;
    color: var(--text1);
}


.TabComponent_tab_window__2rRl7 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
}

.TabComponent_tab_nav__Skb9V {
    padding-right: 8px;
    border-radius: var(--border-radius);
}
.TabComponent_main_tab_content__2tdlE {
    -webkit-user-select: none;
            user-select: none;
    margin-top: 6px;
    flex: 1 1;
    overflow: hidden;
}

.TabComponent_tab_ul__1UbDa,
.TabComponent_tab_ul__1UbDa li,
.TabComponent_tab_ul_left__3rOdG {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.TabComponent_tab_ul__1UbDa {
    display: flex;
    width: 100%;
}

.TabComponent_tab_ul__1UbDa li,
.TabComponent_menu_content__29B5H li,
.TabComponent_tab_ul_left__3rOdG li {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    padding: 10px 0;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    min-width: 0;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    list-style: none;
    color: var(--text2);
    font-weight: 500;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.TabComponent_label_button__2NNi0 {
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
}

.TabComponent_tab_ul__1UbDa li.TabComponent_selected__3_6rc,
.TabComponent_menu_content__29B5H li.TabComponent_selected__3_6rc,
.TabComponent_tab_ul_left__3rOdG li.TabComponent_selected__3_6rc {
    color: var(--accent1);
}

.TabComponent_tab_ul_left__3rOdG li {
    max-width: 25%;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.TabComponent_tab_ul__1UbDa li:hover,
.TabComponent_tab_ul_left__3rOdG li:hover,
.TabComponent_menu_content__29B5H li:hover,
.TabComponent_selected__3_6rc {
    color: var(--accent1);
    transition: var(--transition);
}

.TabComponent_selected__3_6rc button,
.TabComponent_non_selected__C5Zn1 button {
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 19px;
}

.TabComponent_selected__3_6rc button {
    color: var(--accent1);
    font-size: 15px;
    white-space: nowrap;
}

.TabComponent_non_selected__C5Zn1 button {
    color: var(--text3);
    font-size: 13px;
}

.TabComponent_non_selected__C5Zn1:hover button {
    color: var(--accent1);
}

.TabComponent_underline__2iAEA {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent1);
}
.TabComponent_tab_with_option_container__Qc24f {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.TabComponent_tab_ul_left__3rOdG {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.TabComponent_tab_icon_container__2d4J1 {
    background: var(--dark3);
    padding: 4px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.TabComponent_tab_icon_container__2d4J1:hover {
    cursor: pointer;
    background: var(--dark2);
    transition: var(--transition);
}
.TabComponent_tab_icon__3KZmo {
    display: flex;
}

.TabComponent_item_label__3DsTA {
    display: none;
}

.TabComponent_item_label__3DsTA:focus-visible {
    border-bottom: 1px solid var(--text1);
}
@media (min-width: 800px) {
    .TabComponent_tab_icon_container__2d4J1 {
        display: none;
    }
}

@media (min-width: 500px) {
    .TabComponent_item_label__3DsTA {
        display: flex;

        background: transparent;
        outline: none;
        border: none;

        cursor: pointer;
    }

    .TabComponent_selected__3_6rc {
        color: var(--accent1);
    }

    .TabComponent_tab_ul__1UbDa li,
    .TabComponent_menu_content__29B5H li,
    .TabComponent_tab_ul_left__3rOdG li {
        padding: 8px 10px;
    }

    .TabComponent_justify_content_flex_start__tUKFM li {
        justify-content: flex-start;
    }

    .TabComponent_justify_content_center__38eKz li {
        justify-content: center;
    }

    .TabComponent_tab_ul_left__3rOdG {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .TabComponent_tap_option_right__Su4I8 {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-items: baseline;
        max-height: 44px;
    }
}

.ReceiptDisplay_container__3Nc2H {
    width: 100%;
    background: var(--dark2);

    border-radius: 5px;
    padding: 0.4rem 8px;
    display: grid;
    grid-template-columns: 40px auto;
}

.ReceiptDisplay_container__3Nc2H:focus-visible,
.ReceiptDisplay_row__1Zt7y button:focus-visible,
.ReceiptDisplay_row__1Zt7y a:focus-visible,
.ReceiptDisplay_info__3RIhz button:focus-visible,
.ReceiptDisplay_row__1Zt7y svg:focus-visible {
    outline: 1px solid white;
}
.ReceiptDisplay_content__1BGgq {
    display: flex;
    flex-direction: column;
    padding: 0 4px;
}
.ReceiptDisplay_status__2-3ls {
    display: flex;
    margin: auto;
}

.ReceiptDisplay_info__3RIhz {
    display: flex;
    flex-direction: column;
}

.ReceiptDisplay_info__3RIhz div {
    color: var(--text1);
}

.ReceiptDisplay_info__3RIhz p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.ReceiptDisplay_row__1Zt7y {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ReceiptDisplay_row__1Zt7y p {
    color: var(--text2);
}

.BackgroundImages_home_wallpaper_mobile__3LLq8 {
    background: linear-gradient(
            to bottom,
            rgba(13, 17, 23, 0),
            rgba(13, 17, 23, 0.1) 80%,
            rgba(13, 17, 23, 0.4) 85%,
            rgba(13, 17, 23, 0.7),
            rgba(13, 17, 23, 0.9),
            rgba(13, 17, 23, 1)
        ),
        url(/static/media/home_wallpaper_compressed.e043a3d1.png) no-repeat;
}

.BackgroundImages_home_wallpaper__r5Qx5 {
    background: linear-gradient(
            180deg,
            var(--dark1) 0%,
            var(--dark1) 0%,
            rgba(0, 0, 0, 0) 0%,
            var(--dark1) 100%
        ),
        url(/static/media/home_wallpaper_compressed.e043a3d1.png) no-repeat
            center center;
}
.BackgroundImages_home2__2ws34 {
    background: url(/static/media/home2.a356fad6.png) no-repeat;
}
.BackgroundImages_home3__2VlvV {
    background: url(/static/media/home3.4c05727b.png) no-repeat;
}
.BackgroundImages_home4__1xMTC {
    background: url(/static/media/home4.ec05e991.png) no-repeat;
}

.BackgroundImages_background__bkzTn {
    background: url(/static/media/background.3669eb79.png) no-repeat
        center center fixed;
    background-size: cover;
}

.BackgroundImages_ambient_blast_logo__1w2Vk {
    font-size: 50px;
    font-family: 'Lexend Exa';
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    background: linear-gradient(
        90deg,
        #7371fc 0.01%,
        #cdc1ff 49.48%,
        #7371fc 99.99%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Footer_footer_item_container__USqyU {
    display: flex;
    flex-direction: column;
    justify-content: center;

    grid-gap: 8px;

    gap: 8px;
    cursor: pointer;
    padding: 4px;
    border-radius: var(--border-radius);
}

.Footer_footer_item_container__USqyU:hover {
    cursor: pointer;
    background: var(--dark2);
    transition: var(--transition);
}

.Footer_footer_item_container__USqyU h3 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);

    grid-gap: 4px;

    gap: 4px;
}

.Footer_footer_item_container__USqyU p {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.Footer_mobile_version__vAP-_ {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
    margin: 0 2rem;
}

.Footer_started_button__11COI {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    text-align: start;
    color: var(--accent1);
}

.Footer_mobile_version__vAP-_ .Footer_footer_item_container__USqyU h3 {
    font-weight: 300;

    font-size: var(--header2-size);
    line-height: var(--header2-lh);

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: center;
    text-align: center;
}

.Footer_started_button__11COI {
    text-align: center;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.Footer_mobile_version__vAP-_ .Footer_footer_item_container__USqyU p {
    display: none;
}

.Footer_mobile_version__vAP-_ .Footer_footer_item_container__USqyU {
    text-align: center;
}

.Footer_container__st7Zp {
    width: 100%;
    background: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 40.39%,
            rgba(0, 0, 0, 0) 68.28%,
            #000000 100%
        ),
        url(/static/media/home3.4c05727b.png) no-repeat center center;
    mix-blend-mode: lighten;
    background-size: cover;
    background-position: top 30px right 0;
}

.Footer_content__27StV {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 2rem 0;
}

.Footer_row__1mE-5 {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

@media only screen and (min-width: 768px) {
    .Footer_content__27StV {
        width: 1020px;

        flex-direction: row;

        justify-content: space-between;
        grid-gap: 2rem;
        gap: 2rem;
        margin: 0 auto;
        align-items: flex-start;
        text-align: start;
    }
}

@media only screen and (max-width: 768px) {
    .Footer_content__27StV {
        width: 100%;
    }

    .Footer_row__1mE-5 {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .Footer_footer_item_container__USqyU {
        width: 100%;
    }
}

.PoolCard_pool_card__TMrwX {
    cursor: pointer;
    position: relative;
    list-style: none;
    border-radius: var(--border-radius);
}

.PoolCard_main_container__2WwEO {
    width: 270px;
    height: 120px;
    border-radius: var(--border-radius);
    background: var(--dark2);
    padding: 8px;
    z-index: 1;
    cursor: pointer;
    position: relative;
    transition: var(--transition);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.PoolCard_pool_card__TMrwX:hover,
.PoolCard_pool_card__TMrwX:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.PoolCard_row__28vra {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.PoolCard_column__2Mqtd {
    flex: 1 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
}

.PoolCard_token_images__3XSMx {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.PoolCard_tokens_name__1UBPG {
    font-family: var(--font-logo);
    font-weight: 100;
    font-size: var(--header-size);
    line-height: 22px;
    display: flex;
    justify-content: center;
    color: var(--text1);
}

.PoolCard_pool_price_change__2FC6n {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}
.PoolCard_pool_price_title__16XI- {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    color: var(--text1);
    margin-left: 2px;
}

.PoolCard_row_title__19Z1Q {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    /* identical to box height */

    display: flex;
    justify-content: flex-start;
    text-align: flex-start;
    align-items: flex-start;

    /* text/grey light */

    color: var(--text2);
}

.PoolCard_apr__3qKa- {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;

    background: var(--apr-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.PoolCard_vol__1ImDL {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;

    /* text/white */

    color: var(--text1);
}
.PoolCard_change_positive__1JFJt {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: 22px;
    letter-spacing: -0.02em;

    /* other/positive */

    color: var(--positive);
}
.PoolCard_change_negative__2bENq {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: 22px;
    letter-spacing: -0.02em;

    /* other/positive */

    color: var(--negative);
}
.PoolCard_price__1dVF8 {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;

    letter-spacing: -0.02em;
    color: var(--text1);
    font-family: sans-serif;
    /* font-family: var(--mono); */
}

.PoolCard_full_width__39Bp_ {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.PoolCard_full_width_center__11CIl {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .PoolCard_main_container__2WwEO {
        background-color: transparent;

        background: var(--dark2);
    }

    .PoolCard_pool_card__TMrwX:hover,
    .PoolCard_pool_card__TMrwX:focus-visible {
        box-shadow: none;
    }

    .PoolCard_column__2Mqtd:first-of-type {
        align-items: flex-start;
    }
}

.Wallet_container__FqS3c {
    height: 100%;
}

.Wallet_item_container__EWmgx {
    height: calc(100% - 24px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 8px;
}

.Wallet_item_container__EWmgx::-webkit-scrollbar {
    width: 1px;
    display: none;
}

.WalletCard_token_info__33xFO {
    display: grid;
    grid-template-columns: minmax(18ch, 2fr) 3fr;
    width: 100%;
    align-items: center;
    color: var(--text1);
}

.WalletCard_token_icon__2owfj {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
}

.WalletCard_wallet_row__2Ibsq {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    height: 48px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    padding: 0 4px;
    border-radius: var(--border-radius);
}

.WalletCard_wallet_row__2Ibsq:hover {
    transition: var(--transition);
    background: var(--dark2);
    cursor: default;
}

.WalletCard_token_key__RDRgd,
.WalletCard_amount__1BsPc {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--text1);
}

.WalletCard_value__BiSGZ,
.WalletCard_amount__1BsPc {
    display: flex;
    justify-content: flex-end;
    color: var(--text1);
}

.WalletHeader_wallet_row__1zQZY {
    display: grid;
    color: white;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--dark2);
}

.WalletHeader_wallet_row__1zQZY p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    /* text-align: right; */

    height: 16px;
}

.WalletHeader_value__12_M5,
.WalletHeader_amount__rkV15 {
    display: flex;
    justify-content: flex-end;
    margin: 0 5px;
}

.WalletHeader_token__2kiZL {
    margin-left: 30px;
}

.Exchange_container__3wzI5 {
    height: 100%;
    height: calc(100vh - 26.5rem);
}

.Exchange_item_container__2T9JO {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 8px;
}

.Exchange_item_container__2T9JO::-webkit-scrollbar {
    width: 1px;
    display: none;
}

.ExchangeCard_token_info__1adDu {
    display: grid;
    grid-template-columns: minmax(18ch, 2fr) 3fr;
    width: 100%;
    align-items: center;
    color: var(--text1);
}

.ExchangeCard_token_icon__1w_zo {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
}

.ExchangeCard_exchange_row__2GwXT {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    height: 48px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    padding: 0 4px;
    border-radius: var(--border-radius);
}

.ExchangeCard_exchange_row__2GwXT:hover {
    transition: var(--transition);
    background: var(--dark2);
    cursor: default;
}

.ExchangeCard_token_key__ZZl_e,
.ExchangeCard_amount__LIhGu {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--text1);
}

.ExchangeCard_value__2GodT,
.ExchangeCard_amount__LIhGu {
    display: flex;
    justify-content: flex-end;
    color: var(--text1);
}

.ExchangeHeader_exchange_row__1Tykk {
    display: grid;
    color: white;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--dark2);
}

.ExchangeHeader_exchange_row__1Tykk p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    /* text-align: right; */

    height: 16px;
}

.ExchangeHeader_value__1JkrT,
.ExchangeHeader_amount__3tWIo {
    display: flex;
    justify-content: flex-end;
    margin: 0 5px;
}

.ExchangeHeader_token__1PI0M {
    margin-left: 30px;
}

.NoTableData_container__1iGfa {
    width: 100%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.NoTableData_container__1iGfa h2 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text2);
}
.NoTableData_container__1iGfa p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.NoTableData_container__1iGfa button {
    outline: none;
    border: none;
    background: var(--accent1);
    color: var(--text1);
    padding: 4px;
    border-radius: var(--border-radius);
    cursor: pointer;
    text-transform: capitalize;
    transition: var(--transition);
}
.NoTableData_container__1iGfa button:hover {
    background: var(--accent5);
    color: var(--text3);
}

.OpenOrderStatus_filled__9HQ7L,
.OpenOrderStatus_non_filled__2tKFy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
}
.OpenOrderStatus_non_filled__2tKFy {
    border: var(--grey-light-border);
}
.OpenOrderStatus_filled__9HQ7L {
    border: 1px solid var(--positive);
}

.OpenOrderStatus_circular_progress__E_-xg {
    display: block;
    transform: rotate(-90deg);
}

.OpenOrderStatus_circle_background__3yAxN {
    fill: none;
    stroke: var(--text2);
}

.OpenOrderStatus_circle_fill__2MPeg {
    fill: none;
    stroke: var(--positive);
    stroke-linecap: round;
    transform-origin: center;
    transition: stroke-dashoffset 0.3s;
}
/* Desktop screen Sizes */
@media only screen and (min-width: 992px) {
    .OpenOrderStatus_filled__9HQ7L,
    .OpenOrderStatus_non_filled__2tKFy {
        width: 21px;
        height: 21px;
    }
}

.TableMenus_main_container__11MzN {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 4px;
    gap: 4px;
}

.TableMenus_actions_menu__2Y-0B {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.TableMenus_reposition_button__3xjma {
    cursor: pointer;
    height: 23px;

    line-height: var(--body-lh);
    font-size: var(--body-size);
    white-space: nowrap;
    padding: 5px 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--accent1);
    border: 1px solid var(--accent1);
    border-radius: 50px;

    transition: var(--transition);
    z-index: 2;
}

.TableMenus_reposition_button__3xjma:hover {
    color: var(--dark1);
    background: var(--accent1);
}
.TableMenus_dropdown_menu__1It0S {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.TableMenus_dropdown_menu__1It0S button {
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text1);
    outline: none;
}

.TableMenus_dropdown_menu__1It0S button:hover {
    color: var(--accent1);
}

.TableMenus_actions_menu__2Y-0B {
    display: flex;
    justify-content: flex-end;
}

.TableMenus_menu_column__3LSD1 {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
}

.TableMenus_menu_column__3LSD1 button {
    border: 1px solid transparent;
}

.TableMenus_dropdown_button__JFKnc {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}
.TableMenus_dropdown_button__JFKnc:focus-visible {
    border: 1px solid var(--accent1);
}
.TableMenus_dropdown_button__JFKnc path {
    color: var(--dark3);
}
.TableMenus_dropdown_button__JFKnc:hover {
    cursor: pointer;
}

.TableMenus_dropdown_button__JFKnc:hover path,
.TableMenus_dropdown_button__JFKnc:hover circle {
    color: var(--accent1);
    transition: var(--transition);
}
.TableMenus_dropdown_wrapper__1aoSY,
.TableMenus_dropdown_wrapper_active__3nrOE {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    /* transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    right: 30px;
    z-index: 5;
}

.TableMenus_dropdown_wrapper__1aoSY {
    top: 3px;

    opacity: 0;
    pointer-events: none;
}

.TableMenus_dropdown_wrapper_active__3nrOE {
    opacity: 1;
    top: 0;
    pointer-events: auto;
}

.Medal_quiz_medal__37Bck {
    position: relative;
}
.Medal_quiz_medal__circle__2Pwg3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-logo);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    font-weight: 500;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 46px;
    vertical-align: middle;
    position: relative;
    border-width: 0.2em;
    border-style: solid;
    z-index: 1;
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #edeff1;
    text-shadow: 2px 2px 0 #98a6ad;
    background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.Medal_quiz_medal__circle_simple__2qbKW {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-logo);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    font-weight: 500;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 46px;
    vertical-align: middle;
    position: relative;
    border-width: 0.2em;
    border-style: solid;
    z-index: 1;
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: var(--dark2);
    text-shadow: 2px 2px 0 #98a6ad;
    background: var(--title-gradient);
}
.Medal_quiz_medal__circle__gold__1Ru3I {
    box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #fadd40;
    text-shadow: 0 0 4px #9d6c04;
    background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.Medal_quiz_medal__circle__silver__2BaUN {
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #edeff1;
    text-shadow: 0px 0px 4px #98a6ad;
    background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.Medal_quiz_medal__circle__bronze__3TtV8 {
    box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #f7bb23;
    text-shadow: 0 0 4px #7d4604;
    background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}

.Medal_quiz_medal__circle__simple__gDMPi {
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: var(--dark2);
    text-shadow: 0px 0px 4px #98a6ad;
    background: linear-gradient(to bottom right, #00070b 50%, #000e14 50%);
}
.Medal_quiz_medal__ribbon__1YdS5 {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 3px 5px;
    width: 0;
    height: 10px;
    top: 30px;
}
.Medal_quiz_medal__ribbon__left__36FpS {
    border-color: #fc402d #fc402d transparent #fc402d;
    left: 4px;
    transform: rotate(20deg) translateZ(-32px);
}
.Medal_quiz_medal__ribbon__right__3PO5l {
    left: 18px;
    border-color: #f31903 #f31903 transparent #f31903;
    transform: rotate(-20deg) translateZ(-48px);
}

/* .meda */

.Medal_medal_circle__2LTau {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    background: blue;
}

.RangeStatus_in_range_display__2k8aO {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    background: var(--dark2);
    border: unset;
    min-width: 1rem;
    padding: 4px 8px;
    border-radius: 100px;
    color: var(--text1);
    display: inline-flex;
    border-radius: 12px;
    justify-content: space-between;

    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 22px;
}

.RangeStatus_range_container__1fw89 {
    display: flex;
    color: var(--text1);
    position: relative;
}

.RangeStatus_range_text_positive__1gqxf {
    padding: 1px;
    border-radius: 50%;
    background: var(--positive);
    box-shadow: 0 0 0 2px var(--text3);
}
.RangeStatus_range_text_negative__2INbR {
    padding: 1px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--text3);
    background: var(--negative);
}
.RangeStatus_range_text_ambient__Gpxem {
    padding: 1px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--text3);
    background: var(--title-gradient);
}
.RangeStatus_in_range_display__2k8aO {
    display: flex;
}

.RangeStatus_non_filled__2avuz {
    width: 21.5px;
    height: 21.5px;
    border-radius: 50%;
    background: var(--dark2);
    border: var(--grey-light-border);
}

.RangeStatus_small__2E-4l {
    width: 8px;
    height: 8px;
}

.RangeStatus_large__rRpqJ {
    width: 0.625rem;
    height: 0.625rem;
}

/* 
@media only screen and (min-width: 1200px) {
    .in_range_display {
        display: flex;
    }
    .symbol_only_display {
        display: none;
    }
} */

.PriceInfo_main_container__2Gt68 {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.PriceInfo_price_info_container__27Xca {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

/* TOKEN PAIR DETAILS */
.PriceInfo_token_pair_details__2LQNp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.PriceInfo_token_pair_details__2LQNp p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}

.PriceInfo_token_pair_images__3Q_sE {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PriceInfo_align_center__2bQvg {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}
.PriceInfo_earned_container__20WHc,
.PriceInfo_price_status_content__18iW5,
.PriceInfo_value_content__hUR6n {
    padding: 8px;
}
.PriceInfo_value_content__hUR6n,
.PriceInfo_earned_container__20WHc div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.PriceInfo_earned_container__20WHc {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.PriceInfo_earned_container__20WHc,
.PriceInfo_earned_container__20WHc section {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.PriceInfo_earned_container__20WHc p {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PriceInfo_price_status_content__18iW5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
}
.PriceInfo_price_status_content__18iW5 section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.PriceInfo_price_status_content__18iW5 p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.PriceInfo_price_status_content__18iW5 h2 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.PriceInfo_low_range__2qNu4 {
    color: var(--accent5);
}

.PriceInfo_high_range__T0HLK {
    color: var(--accent1);
}

.PriceInfo_ambi_info_text__2YZQb {
    text-align: center;
    background: var(--dark2);
    padding: 4px;
    border-radius: var(--border-radius);
    text-transform: none;
}
/* .order_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2)
} */
.PriceInfo_divider__2N0xI {
    width: 100%;
    height: 1px;
    background: var(--dark3);
    margin: 4px 0;
}
@media only screen and (min-width: 768px) {
    .PriceInfo_price_info_container__27Xca,
    .PriceInfo_value_content__hUR6n,
    .PriceInfo_buy_content__1OhX8,
    .PriceInfo_sell_content__1hUcB,
    .PriceInfo_price_status_content__18iW5 {
        grid-gap: 1rem;
        gap: 1rem;
    }
}

.Apy_apr__5XzKo {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    grid-gap: 8px;
    gap: 8px;
}

.Apy_align_center__1J6Iw {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.Apy_apr__5XzKo p {

    letter-spacing: -0.02em;
}

.Apy_apr_green__1kLQg p {
    /* apr/green */

    background: var(--apr-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* text-shadow: 0px 0px 10px rgba(21, 190, 111, 0.75); */
}

.Apy_apr_red__BMT9R p {
    background: var(--red-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* text-shadow: 0px 0px 10px rgba(190, 100, 21, 0.75); */
}
/* Desktop screen Sizes */
@media only screen and (min-width: 768px) {
    .Apy_apr__5XzKo p {
        /* font-size: var(--body-size);
        line-height: 16px; */
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .Apy_apr__5XzKo p {
        /*     font-size: var(--header2-size);
        line-height: 22px; */
    }
}

.TransactionDetailsModal_tx_details_container__G1RbA {
    width: auto;
    max-width: 1000px;

    transition: var(--transition);
    background-size: cover;
    padding: 1rem;
}

.TransactionDetailsModal_main_outer_container__2DJkr {
    background: url(/static/media/detailsCard.7d5b29c9.png)
        no-repeat;
    background-position: center bottom 40px;
}

.TransactionDetailsModal_main_content__StJ_z {
    cursor: default;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    padding: 1rem 2rem;

    /* width: 800px; */
    /* align-items: center; */
}
.TransactionDetailsModal_control_display_container__23UnN {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0.4rem 0;
}

.TransactionDetailsModal_left_container__3YLLA {
    background: rgba(23, 29, 39, 0.3);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    margin-top: auto;
    margin-bottom: auto;
}

.TransactionDetailsModal_right_container__msEl- {
    /* width: 450px; */
    background: rgba(23, 29, 39, 0.3);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
}

.TransactionDetailsModal_ambi_copyright__AcLus {
    font-family: var(--font-logo);
    display: flex;
    justify-content: center;

    font-weight: 200;
    font-size: var(--header1-size);
    line-height: 30px;
    color: var(--text1);
    padding: 4px 0;
    cursor: default;
}

@media only screen and (max-width:600px) {
    .TransactionDetailsModal_main_content__StJ_z {

        display: grid;
        grid-template-rows: 1fr 0.9fr;
    }
}

@media only screen and (min-width: 768px) {
    .TransactionDetailsModal_outer_container__2mOl4 {
        min-height: 562px;
        height: auto;
        width: 1000px;

        display: grid;
        grid-template-rows: 60px auto;
        grid-gap: 1rem;
        gap: 1rem;
        padding-top: 1rem;
    }

    .TransactionDetailsModal_main_outer_container__2DJkr {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }
    .TransactionDetailsModal_main_content__StJ_z {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 244px auto;
        grid-gap: 1rem;
        gap: 1rem;

        animation: TransactionDetailsModal_fadeIn__3U9Wu ease 1s;
        -webkit-animation: TransactionDetailsModal_fadeIn__3U9Wu ease 1s;
        -moz-animation: TransactionDetailsModal_fadeIn__3U9Wu ease 1s;
        -o-animation: TransactionDetailsModal_fadeIn__3U9Wu ease 1s;
        -ms-animation: TransactionDetailsModal_fadeIn__3U9Wu ease 1s;
    }
}

@keyframes TransactionDetailsModal_fadeIn__3U9Wu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.RangeDetailsHeader_container__1NqPU {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: default;

    height: 60px;
}
.RangeDetailsHeader_logo_container__2NJDn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.RangeDetailsHeader_logo__1ep9V {
    height: 35px;
    margin-left: 18px;
}

.RangeDetailsHeader_logo_text__dwzzV {
    width: 176px;
    margin-left: 10px;
}
.RangeDetailsHeader_settings_control__38OdD {
    display: flex;
    flex-direction: row;

    grid-gap: 8px;

    gap: 8px;
    justify-content: center;
}

.RangeDetailsHeader_settings_control__38OdD div {
    cursor: pointer;
}

.RangeDetailsHeader_info_button__10Jg5 {
    outline: none;
    border: none;
    background: var(--accent1);
    border-radius: var(--border-radius);
    width: 70px;
    height: 25px;
    cursor: pointer;
}

.RangeDetailsSimplify_tx_details_container__rvAOM {
    max-width: 1000px;
}

.RangeDetailsSimplify_main_container__hSUJu {
    width: 100%;
    transition: var(--transition);
    background: var(--dark1);
    cursor: default;

    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 2rem;
    height: 100%;
    height: calc(100% - 5rem);
    overflow-y: hidden;

    /* margin: 0 1rem; */

    animation: RangeDetailsSimplify_fadeIn__3c4Qf ease 1s;
    -webkit-animation: RangeDetailsSimplify_fadeIn__3c4Qf ease 1s;
    -moz-animation: RangeDetailsSimplify_fadeIn__3c4Qf ease 1s;
    -o-animation: RangeDetailsSimplify_fadeIn__3c4Qf ease 1s;
    -ms-animation: RangeDetailsSimplify_fadeIn__3c4Qf ease 1s;
}
.RangeDetailsSimplify_main_container__hSUJu section {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    border: 1px solid var(--dark2);
    padding: 4px;
    grid-gap: 2px;
    gap: 2px;
    display: flex;
    flex-direction: column;
}

.RangeDetailsSimplify_link_row__2hcAD {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

@media only screen and (min-width: 768px) {
    .RangeDetailsSimplify_main_container__hSUJu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .RangeDetailsSimplify_tx_details_container__rvAOM {
        width: 1000px;
        height: 500px;
    }
}

@keyframes RangeDetailsSimplify_fadeIn__3c4Qf {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.TooltipComponent_icon__3Bl_k svg {
    color: var(--text2);
    transition: var(--transition);
}
.TooltipComponent_icon__3Bl_k svg:hover {
    color: white;
}

.TooltipComponent_icon__3Bl_k {
    display: flex;
    justify-content: center;
}

.annotation-line > .priceLine {
    stroke: rgba(97, 71, 247, 0.8);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}


.priceLine {
    stroke: rgba(97, 71, 247, 0.8);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}

.annotation-line > .limitPriceLine {
    stroke: rgba(235, 235, 255);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}


.annotation-line > .verticalLine {
    
    stroke: #61646F;
    stroke-width: 1.5;
    stroke-dasharray: 8 8;
    border: 1px solid #61646F   
}

.transaction_details_graph_placeholder {
    background: none no-repeat;
    background-position: center 90%;
    background-size: cover;

    width: 100%;
    height: 100%;
}

.transaction_details_graph svg:hover {
    color: var(--accent1);
    cursor: default;
}

.RangeActionModal_main_content__F5unA {
    width: 400px;
    height: 100%;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    background: var(--dark1);
    /* padding: 1rem; */
    /* padding: 0 2rem; */
}

.RangeActionModal_remove_range_container__2CQH4 {
    position: relative;
    padding: 16px;
    padding-top: 0px;
    
}

.RangeActionModal_settings_button__11Sav {
    cursor: pointer;
}

.RangeActionModal_remove_range_settings_container__2CmeL {
    position: absolute;

    top: 40px;
}
.RangeActionModal_header_container__30_lN {
    padding: 0 16px;
}

.RangeActionModal_settings_icon__1_azx {
    cursor: pointer;
    animation: RangeActionModal_all__6lTRQ 500ms ease-in-out;
    display: flex;
    justify-content: flex-end;
}

.RangeActionModal_confirmation_container__1Ia70 {
    width: 400px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--dark1);
    /* padding: 1rem; */
    /* align-items: center; */
}

.RangeActionModal_confirmation_content__2yAdC {
    padding: 1rem;
}

.RangeActionModal_button__27s6Z {
    cursor: pointer;
}

.RangeActionModal_confirmation_container__1Ia70 header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.RangeActionModal_confirmation_container__1Ia70 header div {
    cursor: pointer;
}

.RangeActionModal_info_container__2np5i {
    padding: 0 16px;
}

.RangeActionModal_extra_info_container__2Sapf {
    border: 1px solid var(--dark2);
    border-radius: var(--border-radius);
    padding: 8px 16px;

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.RangeActionModal_extra_info_container__2Sapf div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RangeActionModal_button_container__2EtVI {
    padding: 0 16px;
}

.RemoveRangeWidth_range_width_container__3Tu24 {
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--content-bg);
    transition: var(--transition);
}

.RemoveRangeWidth_title__Y2xb4 {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.RemoveRangeWidth_range_width_content__1_mRz {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 12px;
    row-gap: 12px;
}
.RemoveRangeWidth_range_width_input__1PIjD {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12%;
}

.RemoveRangeWidth_range_width_input__1PIjD span {
    color: rgb(218, 214, 214);
}

.RemoveRangeWidth_range_width_title__3WEv4 {
    display: flex;
    justify-content: flex-start;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    margin-top: 10px;
}

.RemoveRangeWidth_percentage_container__1MaqR {
    display: flex;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    align-items: center;
}

.RemoveRangeWidth_percentage_amount__dOiOf {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--text2);
    margin-right: 8px;
}

.RemoveRangeWidth_percentage_options__qeb1s {
    display: flex;
    padding: 0 0rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.RemoveRangeWidth_matching_percentage_button__w-otG,
.RemoveRangeWidth_percentage_option_buttons__3fRw_ {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    overflow: hidden;
    margin: 4px !important;
    background: var(--dark2);
    padding: 0.5rem 1%;
    cursor: pointer;
    color: var(--text2);
    border: none;
    border-radius: 50px;
    padding: 5px 7px;
    background-color: var(--dark2);
    transition: var(--transition);
    border: 1px solid transparent;
}

.RemoveRangeWidth_percentage_option_buttons__3fRw_:hover {
    color: var(--accent1);
    border: 1px solid var(--accent1);
}

.RemoveRangeWidth_percentage_option_buttons__3fRw_:focus-visible,
.RemoveRangeWidth_percentage_input__2uJuR:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.RemoveRangeWidth_matching_percentage_button__w-otG {
    background-color: var(--accent1);
    color: var(--dark1);
}

.RangeActionTokenHeader_container__11sUq {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;
}

.RangeActionTokenHeader_settings_icon__3VdZm {
    cursor: pointer;
}

.RangeActionTokenHeader_token_info__2a718 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
}

.RangeActionTokenHeader_hoverable_icon__1Q1GT {
    fill: var(--text2) !important;
    color: var(--text2) !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RangeActionTokenHeader_hoverable_icon__1Q1GT:hover {
    fill: var(--accent1) !important;
}

.RangeActionInfo_remove_position_info__1zBNE {
    background: var(--dark2);
    border-radius: var(--border-radius);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.RangeActionInfo_token_price__2Iut_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1.125em;
    gap: 1.125em;
}

.RangeActionInfo_token_price__2Iut_ img {
    width: 15px;
}

.RangeActionInfo_ambi_info_text__1NNoJ {
    text-align: center;
    background: var(--dark2);
    padding: 4px;
    border-radius: var(--border-radius);
    text-transform: none;
}

.Row_row__3UwUf {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RangeActionSettings_main_container__2cM8P {
    width: 100%;
    height: 300px;
}

.RangeActionSettings_slippage_tolerance_container__1fUyX {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 0.2rem;

    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.RangeActionSettings_slippage_title__3zakM {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    margin-bottom: 0.2em;
}
.RangeActionSettings_slippage_box__tak-2 {
    display: flex;
    justify-content: center;
    background-color: var(--dark2);
    border-radius: var(--border-radius);
    height: 40px;
    /* border: 1px solid var(--text3); */
    padding: 0.5rem;
}
.RangeActionSettings_slippage_content__ug-Jq {
    width: 100%;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: space-between;
}
.RangeActionSettings_slippage_content__ug-Jq input {
    height: 100%;
    border: 0px;
    border-radius: var(--border-radius);
    background: none;
    font-size: var(--header-size);
    width: auto;
    outline: none;
    color: rgb(255, 255, 255);
    text-align: left;
}

.RangeActionSettings_input_container__3snNp {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.RangeActionSettings_slippage_content__ug-Jq button {
    background: var(--dark1);
    cursor: pointer;
    height: 2rem;
    width: auto;
    min-width: 3.5rem;

    margin-right: 8px;
    border: 1px solid var(--dark3);
    border-radius: 50px;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);

    padding: 0.5rem 1rem;
    transition: var(--transition);

    /* border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, var(--accent1), var(--accent5), var(--accent1));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--dark2) inset;
  transition: box-shadow var(--animation-speed) ease-in-out; */
}

.RangeActionSettings_slippage_content__ug-Jq button:hover {
    box-shadow: none;
    cursor: pointer;
    color: var(--text1);
}

.RangeActionExtraControls_main_container__1rs2h {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    padding: 0 16px;
}

.RangeActionExtraControls_wallet_container__13HhW,
.RangeActionExtraControls_gasless_container__34-ZL {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.RangeActionExtraControls_wallet_container__13HhW {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 33px;
}

.RangeActionExtraControls_wallet_section__3aDXk,
.RangeActionExtraControls_exchange_text__V3Eqo {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    transition: var(--transition);
}

.RangeActionExtraControls_wallet_amounts__laPU8 {
    display: flex;
    flex-direction: column;
}

.RangeActionExtraControls_grey_logo__2oewU img {
    filter: grayscale(100%);
}

.RangeActionExtraControls_gasless_container__34-ZL {
    height: 15px;
}
.RangeActionExtraControls_wallet_container_left__2ufRl {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.RangeActionExtraControls_gasless_text__13Op0 {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.ModalHeader_close_button__nFjWi {
    cursor: pointer !important;
}

.ModalHeader_header_container__OLkvj {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

   
    padding: 1rem;
    border-radius: var(--border-radius);
}

.ModalHeader_header_container__OLkvj svg {
    cursor: pointer;
    color: var(--text2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalHeader_header_container__OLkvj h2 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    display: flex;
    letter-spacing: -0.02em;
    color: var(--text1);
}

.ModalHeader_back_button__Watyv {
    position: absolute;
    padding: 1rem;
    left: 0;
}

.ModalHeader_close_button__nFjWi {
    position: absolute;
    padding: 1rem;
    right: 0;
}

.CircleLoader_circle__2Hv_K {
    display: block;

    /* border: 0.5rem solid #e9e9e9;
    border-top: 0.5rem solid var(--accent4); */
    border-radius: 50%;
    /* position: absolute; */
    box-sizing: border-box;
    top: 0;
    left: 0;
}

.CircleLoader_circle_completed__3n8R6 {
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
}

.CircleLoader_circle_failed__4Ir4M {
    /* min-width: 7rem;
    min-height: 7rem; */
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;

    border-radius: 50%;

    border: 1px solid var(--negative);
}

.CircleLoader_circle_failed__4Ir4M p {
    font-weight: 300;

    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.TransactionDenied_removal_pending__3nU3r {
    width: 100%;
    height: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    grid-gap: 1rem;
    gap: 1rem;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    /* padding: 20px; */
}

.TransactionDenied_animation_container__159NI {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.TransactionDenied_animation_container__159NI h2 {
    color: var(--text1);

    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.TransactionDenied_animation_container__159NI p {
    color: var(--text2);

    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.TransactionDenied_removal_pending_bypass__1R5tH button {
    padding: 3px 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.WaitingConfirmation_wallet_confirm__230nF {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* padding: 20px */
}
.WaitingConfirmation_wallet_confirm__230nF h2,
.WaitingConfirmation_wallet_confirm__230nF div {
    padding: 10px 0;
}

.WaitingConfirmation_wallet_confirm__230nF h2 {
    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}
.WaitingConfirmation_wallet_confirm__230nF div {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.TransactionException_removal_pending__1xpw0 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    grid-gap: 2px;
    gap: 2px;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.TransactionException_formatted_error__3UQkM{
    word-break: break-all;
    height: 50px;
    overflow-y: scroll;
}

.TransactionException_formatted_error_container__1X7T6{

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;

}

.TransactionException_copy_error__2pjl0{
    width: 80%;
    background: none;
    margin: 0 auto;
    cursor: pointer;
    outline: none;
    display: flex;
padding: 4px 8px;
justify-content: space-between;
align-items: center;
border-radius: 4px;
border: 1px solid var(--text2);
align-self: stretch;
color: var(--text2);

}
.TransactionException_copy_error__2pjl0:hover{
    border: 1px solid var(--accent4);
    color: var(--accent4);


}
.TransactionFailed_removal_pending__2RpV6 {
    width: 100%;
    height: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    grid-gap: 1rem;
    gap: 1rem;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.TransactionFailed_animation_container__FntQT {
    min-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.TransactionSubmitted_transaction_submitted__1nNbr {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    /* padding: 20px; */
}
.TransactionSubmitted_transaction_submitted__1nNbr h2 {
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.TransactionSubmitted_noAnimation_submitted__3enYB h2 {
    color: var(--text1);
}
.TransactionSubmitted_transaction_submitted__1nNbr p a {
    cursor: pointer;
    text-decoration: none;
    /* font-size: var(--body-size);
  line-height: var(--body-lh); */
}
.TransactionSubmitted_completed_animation__w46ft {
    display: flex;
    min-height: 200px;

    justify-content: center;
    align-items: center;
}

.TransactionSubmitted_arrow__2X-Of {
    position: relative;
    z-index: 1;
    left: calc(50% - 16px);

    width: 32px;
    height: 32px;
    margin-top: -14px;
    margin-bottom: -14px;
    padding: 4px;

    border: 4px solid var(--header-color);
    border-radius: var(--border-radius);
    background-color: var(--dark2);
}

.TransactionSubmitted_action_buttons__4qH-w {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.TransactionSubmitted_view_etherscan__2FhF6 {
    width: 100%;
    padding: 12px 16px;
    font-size: var(--header-size);

    line-height: var(--header1-lh);
    background: var(--accent1);

    text-decoration: none;
    color: var(--text1);
    border-radius: var(--border-radius);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    transition: var(--transition);
}

.TransactionSubmitted_view_etherscan__2FhF6:hover,
.TransactionSubmitted_view_etherscan__2FhF6:focus-visible {
    background: var(--dark2);
    color: var(--accent1) !important;
    border: 1px solid var(--accent1);
    border-radius: var(--border-radius);
}

.TransactionSubmitted_view_etherscan__2FhF6:hover > svg {
    color: var(--accent1) !important;
}

.TransactionSubmitted_bypass_buttons__Fz7K0 a,
.TransactionSubmitted_bypass_buttons__Fz7K0 button {
    padding: 3px 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.LimitActionInfo_remove_position_info__3cwQy {
    background: var(--dark2);
    border-radius: var(--border-radius);
    padding: 0 8px;

    display: flex;
    flex-direction: column;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.LimitActionInfo_info_container__1qXjH {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    grid-gap: 8px;
    gap: 8px;

    border-bottom: 1px solid var(--dark1);
}
.LimitActionInfo_token_price__1PVnM {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1.125em;
    gap: 1.125em;
}

.LimitActionInfo_token_price__1PVnM img {
    width: 15px;
}

.LimitActionInfo_align_center__3XJxj {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.LimitActionInfo_network_fee_container__3ZNST {
    border: 1px solid var(--dark2);
    border-radius: var(--border-radius);
    padding: 8px 16px;
    margin-top: 8px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.LimitActionSettings_main_container__1mJqO {
    width: 100%;
    height: 100%;
    min-height: 280px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LimitActionSettings_header_container__1CBq6 {
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* background: red; */
}

.LimitActionSettings_header_container__1CBq6 svg {
    cursor: pointer;
    color: var(--text2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.LimitActionSettings_header_container__1CBq6 h2 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    display: flex;
    /* align-items: center;
text-align: center; */
    letter-spacing: -0.02em;

    color: var(--text1);
}

.LimitActionSettings_slippage_tolerance_container__1QFBW {
    height: 100%;
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 8px;
    row-gap: 8px;
    margin-top: 0.2rem;

    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.LimitActionSettings_slippage_title__1ykqA {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    margin-bottom: 0.2em;
}
.LimitActionSettings_slippage_box__2hV3d {
    background-color: var(--dark2);
    border-radius: var(--border-radius);
    /* border: 1px solid var(--text3); */
    padding: 0.5rem;
}
.LimitActionSettings_slippage_content__109DP {
    width: 100%;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: space-between;
}
.LimitActionSettings_slippage_content__109DP input {
    height: 100%;
    border: 0px;
    border-radius: var(--border-radius);
    background: none;
    font-size: var(--header-size);
    width: auto;
    outline: none;
    color: rgb(255, 255, 255);
    text-align: left;
}

.LimitActionSettings_input_container__3QkWZ {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.LimitActionSettings_slippage_content__109DP button {
    background: var(--dark1);
    cursor: pointer;
    height: 2rem;
    width: auto;
    min-width: 3.5rem;

    margin-right: 8px;
    border: 1px solid var(--dark3);
    border-radius: 50px;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);

    padding: 0.5rem 1rem;

    /* border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
        linear-gradient(101deg, var(--accent1), var(--accent5), var(--accent1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--dark2) inset;
    transition: box-shadow var(--animation-speed) ease-in-out; */
}

.LimitActionSettings_slippage_content__109DP button:hover {
    box-shadow: none;
    cursor: pointer;
    color: var(--text1);
}

.LimitActionTokenHeader_container__1IRfg {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;

    margin: 0 16px;
}

.LimitActionTokenHeader_token_info__3LsFK {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
}

.LimitActionModal_main_content_container__1l9Wt {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    grid-gap: 16px;
    gap: 16px;
}

.LimitActionModal_info_container__2bex_ {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    grid-gap: 8px;
    gap: 8px;
}

.TransactionDetailsHeader_container__3mkrc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: default;
    height: 60px;
}

.TransactionDetailsHeader_logo_container__6MYuA {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TransactionDetailsHeader_logo__tQbgt {
    height: 35px;
    margin-left: 18px;
}

.TransactionDetailsHeader_logo_text__3Ipxs {
    width: 176px;
    margin-left: 10px;
}

.TransactionDetailsHeader_settings_control__1mPaK {
    display: flex;
    flex-direction: row;

    grid-gap: 8px;

    gap: 8px;
    justify-content: center;
}

.TransactionDetailsHeader_settings_control__1mPaK div {
    cursor: pointer;
}

.TransactionDetailsHeader_info_button__3n-_i {
    cursor: pointer;
    outline: none;
    border: none;
    background: var(--accent1);
    border-radius: var(--border-radius);
    width: 70px;
    height: 25px;
}

.TransactionDetailsPriceInfo_main_container__1gFR_ {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    padding: 1rem 0;
}

.TransactionDetailsPriceInfo_price_info_container__3PeJ0 {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.TransactionDetailsPriceInfo_info_container__3gbOT {
    width: 100%;

    /* Note: backdrop-filter has minimal browser support */
    /* background: red; */
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border-radius: var(--border-radius);
    padding: 0.6rem;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.TransactionDetailsPriceInfo_divider__1cUWQ {
    height: 1px;
    margin: 1rem 0;
}
.TransactionDetailsPriceInfo_info_text__wFfzp {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1.125em;
    gap: 1.125em;
    text-transform: capitalize;
}

.TransactionDetailsPriceInfo_info_text__wFfzp img {
    width: 15px;
}
.TransactionDetailsPriceInfo_graph_image_container__zpDmD {
    width: 48%;
}
.TransactionDetailsPriceInfo_graph_image_container__zpDmD img {
    width: 100%;
}
.TransactionDetailsPriceInfo_min_max_price__HPcrw {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
}

.TransactionDetailsPriceInfo_min_max_price__HPcrw p {
    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.TransactionDetailsPriceInfo_min_max_content__1I6wt {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: center;
    align-items: center;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
.TransactionDetailsPriceInfo_min_price__1dPoQ,
.TransactionDetailsPriceInfo_max_price__3yc0C {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;
}

.TransactionDetailsPriceInfo_max_price__3yc0C {
    color: var(--accent1);
}
.TransactionDetailsPriceInfo_min_price__1dPoQ {
    color: var(--accent5);
}

/* TOKEN PAIR DETAILS */
.TransactionDetailsPriceInfo_token_pair_details__l8fhm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.TransactionDetailsPriceInfo_token_pair_details__l8fhm p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}
.TransactionDetailsPriceInfo_tx_details__3L2YB {
    /* cursor: pointer; */
    display: flex;
    flex-direction: column;

    padding: 0 1rem;

    /* gap: 8px; */
}
.TransactionDetailsPriceInfo_tx_details__3L2YB div {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
.TransactionDetailsPriceInfo_tx_details__3L2YB p {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    font-weight: 300;
}

.TransactionDetailsPriceInfo_token_pair_images__18dfc {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.TransactionDetailsPriceInfo_align_center__1cOKt {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

@media only screen and (min-width: 768px) {
    .TransactionDetailsPriceInfo_price_info_container__3PeJ0,
    .TransactionDetailsPriceInfo_main_container__1gFR_ {
        grid-gap: 1rem;
        gap: 1rem;
    }
}

.TransactionDetailsSimplify_tx_details_container__3QRla {
    width: 100%;
    height: 100%;
    padding: 1rem;
    animation: TransactionDetailsSimplify_fadeIn__AxYWo ease 1s;
    -webkit-animation: TransactionDetailsSimplify_fadeIn__AxYWo ease 1s;
    -moz-animation: TransactionDetailsSimplify_fadeIn__AxYWo ease 1s;
    -o-animation: TransactionDetailsSimplify_fadeIn__AxYWo ease 1s;
    -ms-animation: TransactionDetailsSimplify_fadeIn__AxYWo ease 1s;
}

.TransactionDetailsSimplify_info_content__3wuBQ {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);

    padding: 1rem;
}

.TransactionDetailsSimplify_link_row__1UPYp {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    cursor: default;
}

@keyframes TransactionDetailsSimplify_fadeIn__AxYWo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.OrderDetailsHeader_container__hIV0i {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: default;
    height: 60px;
}

.OrderDetailsHeader_logo_container__e5cWQ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.OrderDetailsHeader_logo__2e_n4 {
    height: 35px;
    margin-left: 18px;
}

.OrderDetailsHeader_logo_text__1XV0e {
    width: 176px;
    margin-left: 10px;
}
.OrderDetailsHeader_settings_control__3A8uU {
    display: flex;
    flex-direction: row;

    grid-gap: 8px;

    gap: 8px;
    justify-content: center;
}

.OrderDetailsHeader_settings_control__3A8uU div {
    cursor: pointer;
}

.OrderDetailsHeader_info_button__fD-v5 {
    cursor: pointer;
    outline: none;
    border: none;
    background: var(--accent1);
    border-radius: var(--border-radius);
    width: 70px;
    height: 25px;
}

.PriceInfo_main_container__21JAy {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    padding: 1rem 0;
}

.PriceInfo_price_info_container__r_dPj {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

/* TOKEN PAIR DETAILS */
.PriceInfo_token_pair_details__210pE {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.PriceInfo_token_pair_details__210pE p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}

.PriceInfo_token_pair_images__3In-0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PriceInfo_align_center__3T3wT {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}
.PriceInfo_order_type__3CYyj,
.PriceInfo_buy_content__2vBM2,
.PriceInfo_sell_content__2PA9l,
.PriceInfo_price_status_content__3v9zh {
    padding: 8px;
}
.PriceInfo_order_type__3CYyj,
.PriceInfo_buy_content__2vBM2,
.PriceInfo_sell_content__2PA9l {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.PriceInfo_buy_content__2vBM2 p,
.PriceInfo_sell_content__2PA9l p {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.PriceInfo_price_status_content__3v9zh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.PriceInfo_price_status_content__3v9zh section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.PriceInfo_price_status_content__3v9zh p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.PriceInfo_price_status_content__3v9zh h2 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--accent5);
}
/* .order_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2)
} */

@media only screen and (min-width: 768px) {
    .PriceInfo_price_info_container__r_dPj,
    .PriceInfo_order_type__3CYyj,
    .PriceInfo_buy_content__2vBM2,
    .PriceInfo_sell_content__2PA9l,
    .PriceInfo_price_status_content__3v9zh {
        grid-gap: 1rem;
        gap: 1rem;
    }
}

.OrderDetailsSimplify_tx_details_container__3Bs6H {
    max-width: 1000px;
}

.OrderDetailsSimplify_main_container__LlQWd {
    width: 100%;
    transition: var(--transition);
    background: var(--dark1);
    cursor: default;

    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 2rem;
    height: 100%;
    height: calc(100% - 5rem);
    overflow-y: hidden;

    /* margin: 0 1rem; */

    animation: OrderDetailsSimplify_fadeIn__3m_vG ease 1s;
    -webkit-animation: OrderDetailsSimplify_fadeIn__3m_vG ease 1s;
    -moz-animation: OrderDetailsSimplify_fadeIn__3m_vG ease 1s;
    -o-animation: OrderDetailsSimplify_fadeIn__3m_vG ease 1s;
    -ms-animation: OrderDetailsSimplify_fadeIn__3m_vG ease 1s;
}
.OrderDetailsSimplify_main_container__LlQWd section {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    border: 1px solid var(--dark2);
    padding: 4px;

    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}

.OrderDetailsSimplify_link_row__cNqhB {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

@media only screen and (min-width: 768px) {
    .OrderDetailsSimplify_main_container__LlQWd {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .OrderDetailsSimplify_tx_details_container__3Bs6H {
        width: 1000px;
        height: 500px;
    }
}

@keyframes OrderDetailsSimplify_fadeIn__3m_vG {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Points_exchange_row__olsqm {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    height: 48px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    padding: 0 4px;
    border-radius: var(--border-radius);
}

.Points_exchange_row__olsqm:hover {
    transition: var(--transition);
    background: var(--dark2);
    cursor: default;
}

.Points_logo_box__1Eq6s {
    grid-gap: 15px;
    gap: 15px;
    display: flex;
    flex-direction: row;
}

.ProfileSettings_container__1__Np {
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--dark2);
    color: var(--text1);
    /* background: red; */
    z-index: 3;

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    padding: 1rem 2rem;
}

.ProfileSettings_container__1__Np,
.ProfileSettings_nft_modal_container__1yoYH img {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    cursor: pointer;
}
.ProfileSettings_no_image__20wTT {
    width: 65px;
    height: 65px;
    background: var(--dark1);

    border-radius: 8px;
    cursor: pointer;
}

.ProfileSettings_content__3tDhK {
    display: flex;
    justify-content: center;
}

.ProfileSettings_settings_container__1DROT {
    display: flex;
    flex-direction: column;

    width: 306px;
}

.ProfileSettings_settings_container__1DROT section {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.ProfileSettings_content__3tDhK h3 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    color: var(--text1);
}
.ProfileSettings_back_button__1O9W9 {
    cursor: pointer;
}

.ProfileSettings_row__2Rs_O {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ProfileSettings_row__2Rs_O input {
    padding: 2px 10px 2px 4px;
    height: 40px;
    width: 222px;

    border-radius: var(--border-radius);
    background: var(--dark2);
    outline: none;
    border: none;
    text-align: end;
    color: var(--text1);
}

.ProfileSettings_nft_container__unZXw {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.ProfileSettings_settings_container__1DROT .ProfileSettings_save_button__3OCid {
    width: 100%;
    height: 25px;
    margin: 1rem 0;

    font-size: var(--header-size);

    line-height: var(--header1-lh);

    border-radius: var(--border-radius);

    cursor: pointer;
    text-align: center;

    background: transparent;
    text-transform: uppercase;

    color: var(--accent5);
    border-radius: var(--border-radius);
    transition: var(--transition);
    background: var(--title-gradient);
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: 1px solid var(--accent1);
}

.ProfileSettings_settings_container__1DROT .ProfileSettings_save_button__3OCid:hover {
    color: var(--text3);
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
}

/* NFT MODAL------------------------------------*/

.ProfileSettings_nft_modal_container__1yoYH {
    width: 338px;
    height: 276px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.ProfileSettings_nft_modal_container__1yoYH h1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text2);
}

.ProfileSettings_nft_modal_container__1yoYH::-webkit-scrollbar {
    display: none;
}

/* END OF NFT MODAL------------------------------------*/
@media (min-width: 940px) {
    .ProfileSettings_container__1__Np {
        height: calc(100vh - 5.5rem);
        width: 100%;
    }
    .ProfileSettings_content__3tDhK {
        width: 100%;
        /* height: calc(100vh - 5.5rem); */
    }
}

.ProfileSettingsTheme_menu__3CRtM {
    z-index: 999;
    position: relative;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.ProfileSettingsTheme_menu__3CRtM ul,
.ProfileSettingsTheme_menu__3CRtM li {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.ProfileSettingsTheme_menu__3CRtM ul {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    background: var(--dark3);
}
.ProfileSettingsTheme_main_container__30EJ1 {
    position: absolute;
    width: 222px;
    top: 45px;
    right: 0;
}

.ProfileSettingsTheme_main_container__30EJ1 li {
    display: flex;

    color: var(--text1);
}

.ProfileSettingsTheme_main_button__3NNjB {
    -webkit-appearance: button;
    color: var(--text1);
    width: 222px;
    padding: 2px 10px 2px 4px;
    height: 40px;
    border-radius: var(--border-radius);
    background: var(--dark3);
    border: none;

    cursor: pointer;

    /* text-align: left; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.ProfileSettingsTheme_main_button__3NNjB path {
    fill: var(--text1);
}

.ProfileSettingsTheme_theme_item_container__2shC7 {
    /* padding: 10px 1.3rem; */
    cursor: pointer;
    padding: 10px 0;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    /* background: darkblue; */
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.ProfileSettingsTheme_theme_color__1efNz {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.ProfileSettingsSkin_menu__2G1ey {
    position: relative;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.ProfileSettingsSkin_menu__2G1ey ul,
.ProfileSettingsSkin_menu__2G1ey li {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.ProfileSettingsSkin_menu__2G1ey ul {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    background: var(--dark3);
}
.ProfileSettingsSkin_main_container__2YoTQ {
    position: absolute;
    width: 222px;
    top: 45px;
    right: 0;
    z-index: 999;
}

.ProfileSettingsSkin_main_container__2YoTQ li {
    display: flex;

    color: var(--text1);
}

.ProfileSettingsSkin_main_button__1kpvp {
    -webkit-appearance: button;
    color: var(--text1);
    width: 222px;
    padding: 2px 10px 2px 4px;
    height: 40px;
    border-radius: var(--border-radius);
    background: var(--dark3);
    border: none;

    cursor: pointer;

    /* text-align: left; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.ProfileSettingsSkin_main_button__1kpvp path {
    fill: var(--text1);
}

.ProfileSettingsSkin_skin_item_container__1ae6j {
    /* padding: 10px 1.3rem; */
    cursor: pointer;
    padding: 10px 0;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    /* background: darkblue; */
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.ProfileSettingsSkin_skin_color__2kTyM {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.ConfirmationModalControl_main_container__10QfL {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    margin: 1rem;
}

.SlippageTolerance_slippage_tolerance_container__3PTSR {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 8px;
    row-gap: 8px;
}
.SlippageTolerance_slippage_title__cblnR {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    margin-bottom: 0.2em;
}
.SlippageTolerance_slippage_box__MJfXf {
    background-color: var(--dark2);
    border-radius: var(--border-radius);
    /* border: 1px solid var(--text3); */
    height: 33px;
}
.SlippageTolerance_slippage_content__30TDb {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
}
.SlippageTolerance_slippage_content__30TDb input {
    height: 100%;
    border: 0px;
    border-radius: var(--border-radius);
    background: none;
    font-size: var(--header-size);
    width: auto;
    outline: none;
    color: var(--text1);
    text-align: left;
}

.SlippageTolerance_slippage_content__30TDb input::placeholder {
    color: var(--text2);
}

.SlippageTolerance_input_container__5yyo- {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.SlippageTolerance_slippage_content__30TDb button {
    margin: 0 2px;
}

.SendToDexBalControl_main_container__1MGKd {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    margin: 1rem;
}

.ContentContainer_window__WRqch {
    position: relative;

    color: var(--text2);
    border-radius: var(--border-radius);

    background-color: var(--accent1);
    background: var(--accent1);
}

.ContentContainer_main_content__3uTws {
    background-color: transparent;
    border-radius: var(--border-radius);

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.ContentContainer_swap_main_content__2ZQmP {
    box-shadow: var(--gradient-box-shadow);
}

.ContentContainer_swap_bg__2vyOX {
    background: var(--dark1);
}
.ContentContainer_circle__1u-YU,
.ContentContainer_circle2__3Ac0G {
    display: none;
}

.ContentContainer_no_background__7meId {
    background: transparent;
    margin-top: 0;
}

.ContentContainer_main_content__3uTws:hover,
.ContentContainer_main_content__3uTws:focus-within {
    transition: var(--transition);
}

.ContentContainer_swap_route__Y_bM0 {
    background: var(--accent1);
    padding: 1px;
    border-radius: var(--border-radius);
    box-shadow: var(--swap-box-shadow);
}

/* media queries */

@media (min-width: 1200px) {
    .ContentContainer_container__2wjfL {
        max-width: 1140px;
    }

    .ContentContainer_customWidthAuto__37DS1 {
        max-width: 1240px;
    }
}

@media (min-width: 992px) {
    .ContentContainer_container__2wjfL {
        max-width: 960px;
    }
    .ContentContainer_customWidthAuto__37DS1 {
        max-width: auto;
    }
}

@media (min-width: 768px) {
    .ContentContainer_container__2wjfL {
        max-width: 720px;
    }

    .ContentContainer_customWidthAuto__37DS1 {
        max-width: auto;
    }
    .ContentContainer_no_background__7meId:hover,
    .ContentContainer_no_background__7meId:focus-within {
        box-shadow: none;
    }

    .ContentContainer_swap_main_content__2ZQmP {
        padding: 24px;
    }
}
@media (min-width: 1200px) {
    .ContentContainer_container__2wjfL {
        max-width: 420px;
    }
    .ContentContainer_swap_route__Y_bM0 {
        min-width: 430px;
        max-width: 440px;
    }
    .ContentContainer_customWidth_container__3-S0A {
        max-width: 640px;
    }

    .ContentContainer_customWidthAuto__37DS1 {
        max-width: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .ContentContainer_main_content__3uTws {
        background-color: var(--dark1);

        /* width: 90%;
        margin: 0 auto; */
    }

    .ContentContainer_swap_route__Y_bM0,
    .ContentContainer_no_background__7meId {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) {
    .ContentContainer_main_content__3uTws {
        overflow-y: scroll;
    }

    .ContentContainer_swap_route__Y_bM0,
    .ContentContainer_no_background__7meId {
        width: 95%;
        overflow-y: scroll;
    }
}

.RangeBounds_advanced_info_container__2B1t9 {
    padding: 0 32px;
}
.RangeBounds_info_container__3RdXY {
    margin-top: 8px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}
.RangeBounds_advanced_disabled__3PF5j{
    pointer-events: none;
    opacity: 0.2;
    filter: blur(2px);
}
.RangeBounds_denomination_switch_container__YfoAE {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* price info */

.RangePriceInfo_price_info_container__jn-bZ {
    display: flex;
    flex-direction: column;
    transition: var(--transition);
}

.RangePriceInfo_price_info_content__1Mr1l {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    grid-gap: 4px;
    gap: 4px;
}

.RangePriceInfo_price_display__3gFxH {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.RangePriceInfo_price_title__e24ag,
.RangePriceInfo_min_price__2LLxH,
.RangePriceInfo_current_price__1pK15,
.RangePriceInfo_max_price__ECPMh,
.RangePriceInfo_apr_price__1XRhs {
    color: var(--text1);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.RangePriceInfo_min_price__2LLxH,
.RangePriceInfo_current_price__1pK15,
.RangePriceInfo_max_price__ECPMh {
    color: var(--text2);
}

.RangePriceInfo_apr_price__1XRhs {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    color: var(--other-green);
}

/* .min_price {
    color: var(--accent5);
}

.current_price {
    color: var(--text1);
    cursor: pointer;
}

.max_price {
    color: var(--accent1);
} */

.RangePriceInfo_collateral_container__2fPEH {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 2rem;
}

.RangePriceInfo_collateral_display__3UnDL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.RangePriceInfo_collateral_title__2F43a {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.RangePriceInfo_collateral_amount__2gvFW {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    /* font-weight: 400; */
    margin: 5px 0;
}

.Swap_swap_page_container__1aTO8 {
    background: url(/static/media/swap_bg.a433555b.png) no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 56px);

}

.Swap_swap_page_container__1aTO8 > section {
    margin-top: 64px;
    height: auto;
}

.TermsOfService_background__sQJll {
    background: url(/static/media/background.3669eb79.png) no-repeat;
    background-size: cover;

    height: calc(100vh - 56px);
    overflow: hidden;
}

.TermsOfService_container__3BLCw {
    width: 100%;
    max-width: 800px;
    height: 80%;

    overflow: auto;
    margin: 5% auto;

    background: rgba(23, 29, 39, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.TermsOfService_content__3G_9a {
    height: 100%;
    width: 100%;
    padding: 2rem;

    display: flex;
    flex-direction: column;

    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;

    color: var(--text1);
}

.TermsOfService_header__1eJfl {
    font-family: 'Lexend Exa';
    font-weight: 100;
    font-size: 30px;
    line-height: 38px;
}

.TermsOfService_sub_header__1_ANX {
    font-family: 'Lexend Deca';
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;

    margin-top: 2rem !important;
}

.TermsOfService_content__3G_9a p {
    margin-top: 1rem;
}

.TermsOfService_content__3G_9a a {
    text-decoration: underline;
    color: var(--accent1);
}

.TermsOfService_content__3G_9a ul {
    margin-left: 1rem;
}

.NotFound_container__2GCvw {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* 56px is the header height */
    background-color: var(--dark2);
    height: calc(100vh - 56px);
}

.NotFoundLottie_notfound_container__g6mbi {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NotFoundLottie_content__1aKJ7 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.NotFoundLottie_content__1aKJ7 a {
    cursor: pointer;
    padding: 0 0.3rem;
    font-size: var(--header1-size);
    line-height: var(--header2-lh);
    background: var(--title-gradient);
    font-family: var(--font-logo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.Game_game_container__3RHGn {
    max-width: 600px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Game_game_container__3RHGn button {
    max-width: 200px;
    margin: 5px 0;
}

.Game_card_grid__BR5Rz {
    margin: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.GameCard_card__EuyuA {
    position: relative;
    padding: 1px;
    background: var(--title-gradient);
    border: 4px;
}

.GameCard_card__EuyuA img {
    width: 100%;
    display: block;

    border-radius: var(--border-radius);
}

.GameCard_card__EuyuA .GameCard_front__2rld_ {
    transform: rotateY(90deg);
    position: absolute;
    transition: all ease-in 0.2s;
}

.GameCard_flipped__CynaO .GameCard_front__2rld_ {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}
.GameCard_card__EuyuA .GameCard_back__jThyS {
    transition: all ease-in 0.2s;
    transition-delay: 0s;
}

.main_layout_chart {
    transition: all 600ms ease-in-out;
    width: 100%;
    height: 100%;
    color: var(--text2);
    position: relative;
}

text {
    fill: var(--text2);
}

text.market {
    font-size: 13px;
    font-weight: bold;
    fill: black;
}

d3fc-canvas,
d3fc-svg {
    overflow: hidden;
    grid-column: 3;
    grid-row: 3;
}

.chart_grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1% auto 1fr auto minmax(1em, -webkit-max-content);
    grid-template-columns: 1% auto 1fr auto minmax(1em, max-content);
    grid-template-rows: minmax(0em, -webkit-max-content) auto 1fr auto;
    grid-template-rows: minmax(0em, max-content) auto 1fr auto;
    transition: all 600ms ease-in-out;
}

text.crossHairText {
    font-size: 13px;
    font-weight: bold;
}

text.blur {
    filter: blur(7px);
}

text.startDate {
    font-size: 13px;
    font-weight: bolder;
}

text.y_axis {
    font-size: 13px;
    font-weight: bold;
}

.domain,
.tick>path,
.gridline-y,
.gridline-x {
    stroke: var(--text2);
    stroke-opacity: 0.1;
}

.annotation-line>.line {
    stroke: var(--text2);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--text2);
}

.annotation-line>.order_sell {
    stroke: var(--accent2);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--accent2);
}

.annotation-line>.order_buy {
    stroke: var(--accent1);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--accent1);
}

.annotation-line > .marketLine {
    stroke: var(--text2);
    stroke-width: 1;
    stroke-dasharray: 4 2;
    stroke-opacity: 0.4;
    border: 2px dashed var(--text2);
}

.annotation-line>.ghostline {
    stroke: var(--text2);
    stroke-width: 1;
    stroke-opacity: 0.5;
    border: 1px var(--text2);
}

.candlestick .down {
    background-color: var(--accent1);
}

.popup {
    position: absolute;
    text-align: center;
    cursor: pointer;
    /* pointer-events: none; */

    width: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: rgba(10, 10, 19, 0.47);
    padding: 6px;
    font-size: large;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.liqTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.xAxisTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

.liqTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;
    font-size: small;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.tooltip {
    position: absolute;
    pointer-events: none;
    background: rgba(10, 10, 19, 0.35);
    padding: 8px;
    width: 15%;
    box-shadow: 0 8px 24px 0 rgba(31, 38, 135, 0.35);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
    transition: 0.8s all ease-in-out;
}

@keyframes hideAnimation {
    to {
        visibility: hidden;
    }
}

.kHKQUR {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 6px;
    row-gap: 6px;
}

.eJnjNO {
    box-sizing: border-box;
    margin: 1px;
    min-width: 0px;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.block {
    display: flex;
    flex-direction: row;
}

.value_title {
    flex-grow: 8 !important;
    font-weight: 500;
    font-size: var(--body-size);
}

.value {
    font-weight: 500;
    font-size: var(--body-size);
}

.crosshair {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-dasharray: 0.6 0.6;
    stroke-opacity: 1;
}

.indicatorLine {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-dasharray: 0.6 0.6;
    stroke-opacity: 1;
}

.highlightedPrice {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-opacity: 1;
}

label {
    color: rgba(204, 204, 204, 0.63);
    padding-left: 7px;
    padding-top: 2px;
    font-size: var(--body-size);
}

.orderHistoryTooltip-enter {
    opacity: 0;
    transform: translateX(100%);
}

.orderHistoryTooltip-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.orderHistoryTooltip-exit {
    opacity: 1;
    transform: translateX(0);
}

.orderHistoryTooltip-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.orderHistoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #f0f0f8;
    font-size: 16px;
}

.lastCandleDiv {
    position: fixed;
    text-align: center;
    cursor: pointer;
    pointer-events: none;

    background: rgba(10, 10, 19, 0.7);
    padding: 6px;
    font-size: medium;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
    width: 260px;
    z-index: 999999;
}

.lastCandleTooltip-enter {
    opacity: 0;
    transform: translateX(100%);
}

.lastCandleTooltip-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.lastCandleTooltip-exit {
    opacity: 1;
    transform: translateX(0);
}

.lastCandleTooltip-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.scroll {
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.47);
}

#d3fc_group {
    height: 100%;
    /* padding: 1%; */
    display: block;
}

@media (max-width: 1200px) and (min-width: 700px) {
    .popup {
        top: 50% !important;
        padding: 5px;
        font-size: large;
    }
}

@media only screen and (max-width: 1325px) {
    .popup {
        font-size: medium;
        top: 66%;
    }
}

@media only screen and (max-width: 700px) {
    .popup {
        font-size: medium;
        width: 80%;
        top: 49%;
    }
}

@media (max-width: 800px) and (min-width: 500px) {
    .chart_grid {
        height: 80%;
    }
}

@media (max-width: 1200px) and (min-width: 800px) {
    .chart_grid {
        height: 85%;
    }
}

/*  SUBCHART  */

#fee_rate_chart,
#tvl_chart {
    display: grid;
    grid-template-rows: 0px auto 1fr auto;
    grid-template-columns: 1% auto 1fr auto minmax(1em, -webkit-max-content);
    grid-template-columns: 1% auto 1fr auto minmax(1em, max-content);
    height: 20%;
    min-height: 3rem;
}

/* SUBCHART END */

.Divider_divider__1qL5J {
    background: var(--dark3);
    width: 100%;
    
    height: 1px;
}

/* I do not want to change the classnames of the file to CSS modules since I am aware those classnames are being used for the graph data */

.chart-fee,
.chart-tvl,
#chart-volume {
    transition: all 600ms ease-in-out;
}

.main_layout {
    transition: all 600ms ease-in-out;
    width: 100%;
    color: var(--text2);
}

.domain,
.tick > path,
.gridline-y,
.gridline-x {
    stroke: var(--text2);
    stroke-opacity: 0.1;
}

.candlestick .down {
    background-color: var(--accent1);
}

hr {
    display: block;
    height: 0.5px;
    border: 0;
    border-top: 0.5px solid var(--dark3);
}

.img_shimmer {
    display: inline-block;
    -webkit-mask: linear-gradient(
            -60deg,
            black 30%,
            rgba(119, 117, 117, 0.333),
            black 70%
        )
        right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.2s infinite;
    filter: blur(8px);
}
@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

.animatedImg_container {
    width: 100%;
    height: 100%;
    cursor: progress;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: relative; */

    /* 
background:     linear-gradient(
    rgba(142, 118, 186, 0.3), 
    rgba(172, 152, 210, 0.3)
  ),

  url('../../../assets/images/charts/candlestick.png') no-repeat center center ; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover; */

    /* filter: blur(4px) */
}

.animatedImg_container img {
    object-fit: contain;
}
.fetching_text {
    z-index: 9;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(10px); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    color: var(--text1);
}
.animatedImg_container img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}

.TimeFrame_chart_overlay_container__31z30 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.TimeFrame_main_time_frame_container__1_YfL {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.TimeFrame_main_time_frame_container__1_YfL:not(:last-child) {
    border-right: var(--grey-light-border);
}

.TimeFrame_active_selected_button__3NYSz,
.TimeFrame_non_active_selected_button__96cut {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}

.TimeFrame_active_selected_button__3NYSz:hover,
.TimeFrame_non_active_selected_button__96cut:hover {
    /* background: var(--title-gradient); */
    color: var(--text1);
}

.TimeFrame_active_selected_button__3NYSz {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.TimeFrame_non_active_selected_button__96cut {
    color: var(--text2);
}

.TimeFrame_time_frame_mobile_button__3xQU2 {
    background: var(--dark2);
    color: var(--text1);
    padding: 2px 8px;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
}

.TimeFrame_dropdown_menu__22Wy3 {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.TimeFrame_dropdown_wrapper__1xRHn,
.TimeFrame_dropdown_wrapper_active__sFp5b {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    z-index: 2;

    display: flex;
}
.TimeFrame_dropdown_wrapper_active__sFp5b:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
}

.TimeFrame_dropdown_wrapper__1xRHn {
    top: 3px;

    opacity: 0;
    pointer-events: none;
    left: 0;
}

.TimeFrame_dropdown_wrapper_active__sFp5b {
    opacity: 1;
    /* top: 10px; */
    pointer-events: auto;
    left: 40px;
}

.TimeFrame_main_time_frame_container__1_YfL button:focus-visible,
.TimeFrame_active_selected_button__3NYSz:focus-visible,
.TimeFrame_non_active_selected_button__96cut:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.VolumeTVLFee_volume_tvl_container__1Rx6G {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.VolumeTVLFee_volume_tvl_container__1Rx6G:not(:last-child) {
    border-right: var(--grey-light-border);
}

.VolumeTVLFee_active_selected_button__rRI8h,
.VolumeTVLFee_non_active_selected_button__2ERvp {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}

.VolumeTVLFee_active_selected_button__rRI8h:hover,
.VolumeTVLFee_non_active_selected_button__2ERvp:hover {
    /* background: var(--title-gradient); */
    color: var(--text1);
}
.VolumeTVLFee_volume_tvl_container__1Rx6G button:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.VolumeTVLFee_active_selected_button__rRI8h {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.VolumeTVLFee_non_active_selected_button__2ERvp {
    color: var(--text2);
}

.VolumeTVLFee_volume_tvl_fee_mobile_button__3nhCN {
    background: var(--dark2);
    color: var(--text1);
    padding: 2px 8px;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
}

.VolumeTVLFee_dropdown_menu__1cjM7 {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.VolumeTVLFee_dropdown_wrapper__3EZNk,
.VolumeTVLFee_dropdown_wrapper_active__2OjRN {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    z-index: 2;

    display: flex;
}
/* .dropdown_wrapper_active:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
} */

.VolumeTVLFee_dropdown_wrapper__3EZNk {
    top: 3px;

    opacity: 0;
    pointer-events: none;
    left: 0;
}

.VolumeTVLFee_dropdown_wrapper_active__2OjRN {
    opacity: 1;
    top: 30px;
    pointer-events: auto;
    left: 0;
    /* right: 5px; */
}

.CurveDepth_curve_depth_container__14GV3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.CurveDepth_curve_depth_container__14GV3:not(:last-child) {
    border-right: var(--grey-light-border);
}

.CurveDepth_active_selected_button__35nxj,
.CurveDepth_non_active_selected_button__2xkmN {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}
.CurveDepth_curve_depth_container__14GV3 button:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.CurveDepth_active_selected_button__35nxj:hover,
.CurveDepth_non_active_selected_button__2xkmN:hover {
    /* background: var(--title-gradient); */
    color: var(--text1);
}

.CurveDepth_active_selected_button__35nxj {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.CurveDepth_non_active_selected_button__2xkmN {
    color: var(--text2);
}

.CurveDepth_curve_depth_mobile_button__1bTJd {
    background: var(--dark2);
    color: var(--text1);
    padding: 2px 8px;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
    text-transform: capitalize;
}

.CurveDepth_dropdown_menu__2toYU {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.CurveDepth_dropdown_wrapper__y2Ihl,
.CurveDepth_dropdown_wrapper_active__4F-yf {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    z-index: 2;

    display: flex;
}
.CurveDepth_dropdown_wrapper_active__4F-yf:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
}

.CurveDepth_dropdown_wrapper__y2Ihl {
    top: 3px;

    opacity: 0;
    pointer-events: none;
    left: 0;
}

.CurveDepth_dropdown_wrapper_active__4F-yf {
    opacity: 1;
    /* top: 10px; */
    pointer-events: auto;
    right: 70px;
}

.CurrentDataInfo_chart_overlay_container__3NdJv {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.CurrentDataInfo_chart_tooltips__mkoJE {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0;
    height: 40px;
}

.CurrentDataInfo_current_data_info__37Qsb {
    padding: 0.3rem 0 0 0.3rem;

    /* extra small */

    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--mini-lh);

    /* text/grey light */

    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    color: var(--text2);
}

.CurrentDataInfo_settings_container__2HvMx:not(:last-child) {
    border-right: var(--grey-light-border);
}

.CurrentDataInfo_active_selected_button__1HzrU,
.CurrentDataInfo_non_active_selected_button__2XBK6 {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}
.CurrentDataInfo_settings_container__2HvMx button:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

@media screen and (min-width: 768px) {
    .CurrentDataInfo_active_selected_button__1HzrU:hover,
    .CurrentDataInfo_non_active_selected_button__2XBK6:hover {
        /* background: var(--title-gradient); */
        color: var(--text1);
    }
}

.CurrentDataInfo_active_selected_button__1HzrU {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.CurrentDataInfo_non_active_selected_button__2XBK6 {
    color: var(--text2);
}

.CurrentDataInfo_border_left__4gToT {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.Pagination_pagination_container__3LcNC {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Pagination_pagination_inside_container__3OqiQ {
    /* width: 600px; */
    background: var(--dark2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* transition: all 500ms ease-in-out; */
}

.Pagination_expanded__1iRrG {
    width: 600px;
    /* transition: all 500ms ease-in-out; */
}

.Pagination_not_expanded__2TaKi {
    width: 100px;
    /* transition: all 500ms ease-in-out; */
}

.Pagination_pagination_content__1GuE3 {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    max-width: 400px;
    overflow-x: scroll;
    border-radius: var(--border-radius);
    scroll-behavior: smooth;
}

.Pagination_pagination_content__1GuE3::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.Pagination_pagination_content__1GuE3 li {
    text-decoration: none;
    list-style: none;
}

.Pagination_pagination_content__1GuE3 button {
    outline: none;
    border: none;
    background: transparent;
    color: var(--text2);

    width: 40px;
    height: 40px;
}

.Pagination_dot__m1DCs {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Pagination_page_active__2uM0s button {
    color: var(--text1);
    cursor: pointer;
    /* background: var(--dark1); */
    text-shadow: 0px 0px 10px rgba(254, 255, 254, 0.75);
    background: var(--dark2);
}

.Pagination_page__3WJRk button {
    cursor: pointer;
}

.Pagination_page__3WJRk button:hover {
    background: var(--dark1);
    color: var(--text1);
    transition: all 500ms ease-in-out;
}

.Pagination_scroll_button__2vdxu {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 35px 20px rgba(0, 0, 0, 0.3);

    background: var(--dark2);
}

.Pagination_scroll_button__2vdxu svg {
    color: var(--text3);
}

.Pagination_scroll_button__2vdxu svg:hover {
    color: var(--text1);
}

.Pagination_text_info__1mcn8 {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

/* pool not initialized */

.NoChartData_pool_not_initialialized_container__2WzXI {
    width: 100%;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
}
.NoChartData_pool_init_bg__2iYoz {
    width: 480px;
    height: 204px;
    border-radius: var(--border-radius);
    padding: 1px;
}

.NoChartData_hide_chart__ebFdq {
    cursor: pointer;
}

.NoChartData_pool_not_initialialized_content__3NhTt {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.NoChartData_pool_not_init_inner__3O-Da {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.NoChartData_pool_not_initialialized_content__3NhTt h2 {
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    color: var(--text1);
    font-weight: 300;
}
.NoChartData_pool_not_initialialized_content__3NhTt h3 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text3);
    font-weight: 300;
}

.NoChartData_initialize_link__1V2pr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 12px 3rem;
    font-size: var(--header-size);
    line-height: var(--header1-lh);

    height: 48px;
    cursor: pointer;
    text-align: center;

    background: transparent;
    text-transform: capitalize;

    color: var(--text1);
    border-radius: var(--border-radius);
    transition: all var(--animation-speed) ease-out;

    background: linear-gradient(
        90deg,
        rgba(115, 113, 252, 0) 0%,
        rgba(115, 113, 252, 0) 71.21%,
        var(--accent1) 100%
    );
    text-decoration: none;
    border: 1px solid var(--accent1);
}

.NoChartData_initialize_link__1V2pr:hover {
    color: var(--dark1);
    background: linear-gradient(to left, var(--accent1) 34%, var(--accent5) 65%)
        right;
    background-size: 300% 100%;
}
.NoChartData_disabled_button__3ousb {
    pointer-events: none;
    opacity: 0.4;
}
@keyframes NoChartData_rotating__2heTT {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.NoChartData_isFetching__1n1k6 svg {
    animation: NoChartData_rotating__2heTT 1s linear infinite;
}

/* .extra_info_container{
    display: flex;
    flex-direction: column;
    margin: 2px 0;
} */

.InitPoolExtraInfo_extra_info_content__3o7tN {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 4px;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    min-height: 33px;
}

.InitPoolExtraInfo_gas_pump__1I1vG,
.InitPoolExtraInfo_token_amount__3fN3l {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.InitPoolExtraInfo_gas_pump__1I1vG svg {
    margin-right: 5px;
}

.InitPoolExtraInfo_token_amount__3fN3l svg {
    margin-left: 1rem;
}

.InitPoolExtraInfo_extra_details__3NwDD {
    border: 1px solid var(--border);

    border-radius: var(--border-radius);

    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.InitPoolExtraInfo_extra_row__1vhFu {
    display: flex;
   

    justify-content: space-between;
    align-items: center;
}

.InitPoolExtraInfo_align_center__BOnnD {
    display: flex;
    flex-direction: row;

    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

@media only screen and (min-width: 700px) {
    .InitPoolExtraInfo_align_center__BOnnD {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
        text-align: start;
    }
    .InitPoolExtraInfo_extra_info_content__3o7tN {
        padding: 0 10%;
    }
    .InitPoolExtraInfo_data__q_1Le {
        text-align: end;
    }

    .InitPoolExtraInfo_extra_details__3NwDD {
        padding: 8px;
        grid-gap: 8px;
        gap: 8px;
        height: auto;
    }

    .InitPoolExtraInfo_extra_row__1vhFu {
        flex-direction: row;
    }
}

.RepositionHeader_title__2ROjh {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: var(--text1);
}

.RepositionHeader_settings_icon__3aEcP {
    margin: 0.5rem 0 0.5rem 1rem;
    cursor: pointer;
    /* height: 30px;
    width: 30px; */
}

.RepositionHeader_close_icon__1RIi_ {
    cursor: pointer;
    margin: 0.5rem 1rem 0.5rem 0;
    height: 30px;
    width: 30px;
}

.RepositionPriceInfo_price_info_container__18kWN {
    padding: 0.5rem 0 0;
}

.RepositionPriceInfo_price_info_content__1Hlgy {
    /* background: rgb(19, 2, 2); */
    padding: 0.5rem 1.5rem;
}
.RepositionPriceInfo_apr_display__1HQdo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.RepositionPriceInfo_apr_display__1HQdo p:first-of-type {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text1);

    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.RepositionPriceInfo_apr_display__1HQdo p:nth-of-type(2) {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--other-green);
}

.RepositionPriceInfo_row_display__37Owl {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    font-size: var(--body-size);
    line-height: var(--body-lh);

    height: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    gap: 4px;
}

.RepositionPriceInfo_row_display__37Owl p:first-of-type,
.RepositionPriceInfo_row_display__37Owl p:nth-of-type(3) {
    color: var(--text1);
    /* background: red; */
}

.RepositionPriceInfo_row_display__37Owl p:first-of-type {
    text-align: start;
}
.RepositionPriceInfo_row_display__37Owl p:nth-of-type(3) {
    text-align: end;
}
.RepositionPriceInfo_row_display__37Owl p:nth-of-type(2) {
    color: var(--text2);
    text-align: center;
}

.RepositionPriceInfo_divider__2Vn6a {
    width: 100%;
    background: var(--dark3);
    height: 1px;
    margin: 0.5rem auto;
    border-radius: 9999;
}

.RepositionPriceInfo_gas_pump_dropdown__3xCwA {
    background: transparent;
    outline: none;
    border: none;
    margin: 0.25rem 2rem 0;
}
.RepositionPriceInfo_gas_pump_dropdown__3xCwA button {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);

    cursor: pointer;
    margin: 0.5rem auto;
    margin-bottom: 0.5rem;
    padding: 0 0.25rem;
}
.RepositionPriceInfo_gas_pump_dropdown__3xCwA button:hover {
    background: var(--dark2);
    border-radius: var(--border-radius);
}
.RepositionPriceInfo_gas_pump_dropdown__3xCwA button:focus-visible {
    border: 1px solid var(--text1);
}
.RepositionPriceInfo_dropdown_content__Gdqud {
    border: 1px solid var(--dark3);
    border-radius: 0.25rem;
    padding: 0.5rem;

    transition: var(--transition);
}

.RepositionPriceInfo_gas_pump_dropdown__3xCwA p {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.25rem;
    gap: 0.25rem;
}

.RepositionPriceInfo_extra_row__1_Qg9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.RepositionPriceInfo_align_center__3qna3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    vertical-align: center;
}

.RepositionPriceInfo_extra_row__1_Qg9 p {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.ConfirmRepositionModal_modal_container__1WgEg {
    width: 400px;
    min-height: 300px;
}

.ConfirmRepositionModal_modal_footer__1noot {
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.5rem;
}

.ConfirmRepositionModal_modal_content__6B_ci {
    padding: 0 20px;
}

.ConfirmRepositionModal_confirm_range_modal_container__O_qvu {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--dark1);
}

/* range header */
.ConfirmRepositionModal_position_display__1MKw- {
    display: flex;
    padding: 0.5rem 0;

    justify-content: space-between;
    align-items: center;
}
.ConfirmRepositionModal_token_display__1U1hk {
    display: flex;

    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.ConfirmRepositionModal_token_symbol__eTST9 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);

    font-weight: 300;
    color: var(--text1);
}
.ConfirmRepositionModal_fee_tier__9VtIA {
    font-size: 14px;
    font-weight: 500;

    padding: 5px;

    background-color: var(--header-color);
}

.ConfirmRepositionModal_tokens__33Crb {
    display: flex;
    flex-direction: row;

    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.ConfirmRepositionModal_tokens__33Crb img {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    background: white;
}

.ConfirmRepositionModal_range_display__3ygvI {
    font-size: 14px;
    font-weight: 500;

    display: inline-flex;

    padding: 4px 6px;

    color: rgb(255, 255, 255);
    border: unset;
    border-radius: 0.5rem;
    background-color: var(--content-bg);
    align-items: center;
    justify-content: center;
}

/* fee tier display */
.ConfirmRepositionModal_fee_tier_display__32yeO {
    padding: 1rem;

    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--dark2);
}
.ConfirmRepositionModal_fee_tier_container__3n7Vo {
    display: grid;

    grid-auto-rows: auto;
    grid-row-gap: 12px;
    row-gap: 12px;
}

.ConfirmRepositionModal_detail_line__1JR1c div {
    display: flex;

    align-items: center;
}

.ConfirmRepositionModal_detail_line__1JR1c {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);

    display: flex;

    align-items: center;
    justify-content: space-between;
}

.ConfirmRepositionModal_detail_line__1JR1c div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.ConfirmRepositionModal_divider__pMgXS {
    background: var(--dark1);
    height: 1px;
    width: 100%;
}

.Reposition_repositionContainer__3NDmD {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}
.Reposition_reposition_content__cqhYV {
    padding: 0 1rem;

    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem;
}

/* Switch buttons */
.Reposition_reposition_toggle_container__1tOFJ {
    /* width: 100%; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: var(--dark2);
    border-radius: var(--border-radius);
    outline: none;
    /* max-width: 200px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    gap: 8px;
}
.Reposition_reposition_toggle_container__1tOFJ a {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    padding: 4px 1.5rem;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    border: none;
    outline: none;
    text-align: center;
    border-radius: var(--border-radius);
}
.Reposition_reposition_toggle_container__1tOFJ a:hover {
    color: var(--text1);
}
.Reposition_active_button_toggle__9W67u {
    color: rgb(0, 0, 0);
    background: var(--accent1);
}

.Reposition_non_active_button_toggle__2scEg {
    color: var(--text2);
    background-color: transparent;
}

.Reposition_view_etherscan__PXl8L {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px;

    font-size: var(--body-size);
    line-height: var(--body-lh);

    color: var(--accent1);
    cursor: pointer;
    padding: 8px 0;
}

.Reposition_button_container__3aKmM {
    margin: auto 2rem;
}

/* SIDEBAR FOOTER */

.SidebarFooter_sidebar_footer__13ny6 {
    z-index: 999;
    width: 100dvw;
    bottom: 0;

    transition: width var(--animation-speed) ease;

    background-color: var(--dark1);
    height: 64px;
 
    display: none;
}

.SidebarFooter_position_sticky__3A0G0 {
    position: -webkit-sticky;
    position: sticky;
}

.SidebarFooter_position_absolute__2xgp5 {
    position: absolute;
}

.SidebarFooter_sidebar_footer__13ny6 span{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}
.SidebarFooter_sidebar_footer__13ny6 a, .SidebarFooter_sidebar_footer__13ny6 span {
    cursor: pointer;
    display: flex;
    flex: 1 0;
    flex-flow: column;
    align-items: center;
    padding: 9px 3px;
    font-size: 11px;
}
.SidebarFooter_sidebar_footer__13ny6 a img, .SidebarFooter_sidebar_footer__13ny6 span img,
.SidebarFooter_sidebar_footer__13ny6 a svg, .SidebarFooter_sidebar_footer__13ny6 span svg {
    width: 20px;
}

@media only screen and (min-width: 500px) {
    .SidebarFooter_sidebar_footer__13ny6 a, .SidebarFooter_sidebar__F-3BI button {
        flex: 1 0;
        padding: 9px 8px;
        font-size: var(--body-size);
    }

}

/* Small screens */
@media only screen and (max-width: 800px) {
    .SidebarFooter_sidebar__F-3BI {
        display: none;

        bottom: 0;
        width: 100vw;
        height: 5rem;
        background: var(--dark2);
        z-index: 2;
    }
    .SidebarFooter_sidebar_footer__13ny6 {
        display: flex;
    }
}

.AppOverlay_main__leJ5r {
    z-index: 999999;
}
.AppOverlay_tutorial_navigation_container__3t0ps {
    z-index: 999999;
    position: absolute;
    bottom: 30px;
    right: 90px;
    width: 138.86px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;

    /* dark/secondary */

    background: var(--dark2);
    /* glow/standard */

    box-shadow: 0px 0px 100px rgba(205, 193, 255, 0.3),
        0px 0px 60px rgba(205, 193, 255, 0.3),
        0px 0px 20px rgba(205, 193, 255, 0.3),
        0px 0px 12px rgba(205, 193, 255, 0.3),
        0px 0px 6px rgba(205, 193, 255, 0.3),
        0px 0px 3px rgba(205, 193, 255, 0.3);
    border-radius: var(--border-radius);
}

.AppOverlay_tutorial_navigation_container__3t0ps button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
}

.OverlayComponent_overlay_wrapper__183Pz,
.OverlayComponent_overlay_wrapper_active__1J3jm {
    padding: 1rem;
    /* width: 100%; */
    /* height: 400px; */
    /* position: absolute; */
    position: fixed;
    background: var(--dark2);
    width: 200px;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 8px;
    gap: 8px;

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.OverlayComponent_overlay_wrapper_active__1J3jm:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
}

.OverlayComponent_overlay_wrapper__183Pz {
    opacity: 0;
    pointer-events: none;
}

.OverlayComponent_overlay_wrapper_active__1J3jm {
    opacity: 1;

    pointer-events: auto;
    z-index: 99999999999;
}

.OverlayComponent_overlay_wrapper_active__1J3jm h3 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}
.OverlayComponent_overlay_wrapper_active__1J3jm p {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text1);
}

.WalletModalWagmi_main_container__1Yla0 {
    justify-content: space-between;
    padding: 1rem 0;
    background: var(--dark1);
}

.WalletModalWagmi_main_container__1Yla0 a:hover,
.WalletModalWagmi_main_container__1Yla0 a:focus-visible {
    text-decoration: underline;
    -webkit-text-decoration-color: var(--text1);
            text-decoration-color: var(--text1);
}

.WalletModalWagmi_main_container__1Yla0,
.WalletModalWagmi_main_container__1Yla0 section,
.WalletModalWagmi_magic_login_container__2BF1r section {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.WalletModalWagmi_email_button__2yuJK {
    cursor: pointer;
    width: 100%;
    background: var(--dark2);
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    grid-gap: 8px;
    gap: 8px;

    border-radius: var(--border-radius);

    color: var(--text2);
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
}

/* learn content */

.WalletModalWagmi_learn_container__2dAlH {
    font-size: var(--header2-size);
    line-height: 22px;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    height: 100px;
    justify-content: flex-end;

    color: var(--text1);
}

.WalletModalWagmi_learn_container__2dAlH a {
    text-decoration: none;
    color: var(--accent1);
}

/* connect with email */

.WalletModalWagmi_magic_logic_input_container__2T5wR {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    background: var(--dark2);
    border-radius: var(--border-radius);
    height: 48px;
}

.WalletModalWagmi_magic_login_container__2BF1r {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}
.WalletModalWagmi_magic_login_container__2BF1r input {
    background: transparent;
    outline: none;
    border: none;
    padding: 0 8px;
    color: var(--text1);
}

.WalletModalWagmi_magic_login_container__2BF1r h2 {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.WalletModalWagmi_magic_login_container__2BF1r img {
    width: 114.37px;
    height: 16px;
}

.WalletModalWagmi_different_wallet__2j5RS {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--accent1);

    bottom: 0;
}

.WalletModalWagmi_tos_content__2ie7A {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    color: var(--text1);
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.WalletModalWagmi_tos_content__2ie7A a {
    text-decoration: none;
    color: var(--accent1);
    white-space: nowrap;
}

.WalletModalWagmi_metamask_pending_container__1y9uH {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-height: 220px;
    background: var(--dark1);

    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    grid-gap: 10px;
    gap: 10px;
    padding: 1rem;
}

.WalletModalWagmi_wall_buttons_container__3i-yp {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;

    padding: 1rem;
}

.WalletButton_container__1AS1- {
    cursor: pointer;
    width: 100%;
    background: var(--dark2);
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 48px;
    grid-gap: 8px;
    gap: 8px;

    border-radius: var(--border-radius);
    margin: 4px 0;
}

.WalletButton_container__1AS1-:hover,
.WalletButton_container__1AS1-:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}
.WalletButton_container__1AS1- img {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
}

.WalletButton_wallet_name__2Djyu {
    color: var(--text2);
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
}

.GateWallet_main_container__36OGo {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
    padding: 8px 16px;
    background: var(--dark1);
    border-radius: var(--border-radius);
    text-align: center;
    padding-bottom: 1rem;
    height: 100%;
}

.GateWallet_main_container__36OGo section {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.GateWallet_first_section__3cZhu {
    display: flex;
    justify-content: center;

    margin-top: 1rem;
}

.GateWallet_main_container__36OGo p {
    color: var(--text1);
    font-size: var(--header-size);

    line-height: 22px;
}

.GateWallet_checkbox__2WHmv {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.GateWallet_main_container__36OGo a {
    color: var(--accent1);
    text-decoration: underline;
}

@media only screen and (min-width: 768px) {
    .GateWallet_main_container__36OGo {
        width: 510px;

        height: 250px;
    }
}

.GlobalPopup_container__2rKu- {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.GlobalPopup_container__2rKu- header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--text2);
}

.GlobalPopup_container__2rKu- header div {
    cursor: pointer;
}

.GlobalPopup_global_popup__3sZkJ,
.GlobalPopup_global_popup_active__16xv0 {
    max-width: 250px;
    padding: 20px;
    width: auto;
    height: auto;
    position: absolute;
    background: var(--dark2);
    border-radius: 15px;
    box-shadow: 0px 35px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    right: 30px;
    z-index: 9999999;

    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.GlobalPopup_popup_left__2vifH {
    left: 30px;
}

.GlobalPopup_popup_right__1MeOy {
    right: 30px;
}

.GlobalPopup_popup_center__2nJa0 {
    left: 0;
    right: 0;
}
.GlobalPopup_global_popup__3sZkJ {
    bottom: 10px;

    opacity: 0;
    pointer-events: none;
}

.GlobalPopup_global_popup_active__16xv0 {
    opacity: 1;

    bottom: 85px;
    pointer-events: auto;
}

.SwitchNetwork_outside_modal__gcedo {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(1, 0, 16, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    transition: var(--transition);
}

.SwitchNetwork_modal_container__3AvHi {
    padding: 1px;
    background: var(--title-gradient);
    height: auto;
    min-width: 347px;
    border-radius: var(--border-radius);
}
.SwitchNetwork_modal__18Mmc {
    display: flex;
    flex-direction: column;

    color: white;

    background-color: var(--dark1);

    align-items: center;
    border-radius: var(--border-radius);
    height: auto;
}

.SwitchNetwork_modal_header__1VxxP {
    font-size: 14px;
    font-weight: 500;

    display: flex;
    grid-gap: 8px;
    gap: 8px;

    width: 100%;
    padding: 10px 20px;
    height: 56px;
    /* padding: 10px 20px; */

    letter-spacing: 1px;
    text-transform: capitalize;

    /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */

    color: var(--text-grey-white);

    align-items: center;
    justify-content: center;
}

.SwitchNetwork_modal_header__1VxxP img {
    max-width: 100%;
    margin: 0 10px;
}

.SwitchNetwork_close_button__37kuB {
    width: 15px;

    cursor: pointer;

    color: white;
}

.SwitchNetwork_modal_content__Obt8V {
    width: 100%;
    padding: 1rem;
    height: auto;
}

.SwitchNetwork_content_title__3zZ5Y {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--header2-size);
    line-height: 22.5px;
    font-weight: 300;
    text-align: center;
}

.NetworkButton_networkButton__1MS_i {
    font-size: var(--header2-size);
    font-weight: 300;
    line-height: var(--header2-lh);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px;

    width: 100%;
    margin-top: 1rem;
    padding: 1rem 0.75rem;

    cursor: pointer;
    transition: color 0.15s ease-in-out;
    text-align: center;

    color: var(--text1);
    border: 1px solid var(--text3);
    border-radius: var(--border-radius);
    outline: none;
    border: none;
}
.NetworkButton_networkButton__1MS_i:hover {
    filter: brightness(85%);
}

.NetworkButton_networkButton__1MS_i img {
    width: 50px;
}

