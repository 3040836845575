.main_container {
    width: 100%;
    height: 100%;
    min-height: 280px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header_container {
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* background: red; */
}

.header_container svg {
    cursor: pointer;
    color: var(--text2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_container h2 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    display: flex;
    /* align-items: center;
text-align: center; */
    letter-spacing: -0.02em;

    color: var(--text1);
}

.slippage_tolerance_container {
    height: 100%;
    display: grid;
    grid-auto-rows: auto;
    row-gap: 8px;
    margin-top: 0.2rem;

    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.slippage_title {
    display: flex;

    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    margin-bottom: 0.2em;
}
.slippage_box {
    background-color: var(--dark2);
    border-radius: var(--border-radius);
    /* border: 1px solid var(--text3); */
    padding: 0.5rem;
}
.slippage_content {
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.slippage_content input {
    height: 100%;
    border: 0px;
    border-radius: var(--border-radius);
    background: none;
    font-size: var(--header-size);
    width: auto;
    outline: none;
    color: rgb(255, 255, 255);
    text-align: left;
}

.input_container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.slippage_content button {
    background: var(--dark1);
    cursor: pointer;
    height: 2rem;
    width: auto;
    min-width: 3.5rem;

    margin-right: 8px;
    border: 1px solid var(--dark3);
    border-radius: 50px;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);

    padding: 0.5rem 1rem;

    /* border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
        linear-gradient(101deg, var(--accent1), var(--accent5), var(--accent1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--dark2) inset;
    transition: box-shadow var(--animation-speed) ease-in-out; */
}

.slippage_content button:hover {
    box-shadow: none;
    cursor: pointer;
    color: var(--text1);
}
