.remove_position_info {
    background: var(--dark2);
    border-radius: var(--border-radius);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.token_price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.125em;
}

.token_price img {
    width: 15px;
}

.ambi_info_text {
    text-align: center;
    background: var(--dark2);
    padding: 4px;
    border-radius: var(--border-radius);
    text-transform: none;
}
