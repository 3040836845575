.container {
    cursor: pointer;
    width: 100%;
    background: var(--dark2);
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 48px;
    gap: 8px;

    border-radius: var(--border-radius);
    margin: 4px 0;
}

.container:hover,
.container:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}
.container img {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
}

.wallet_name {
    color: var(--text2);
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
}
