.transaction_submitted {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    /* padding: 20px; */
}
.transaction_submitted h2 {
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.noAnimation_submitted h2 {
    color: var(--text1);
}
.transaction_submitted p a {
    cursor: pointer;
    text-decoration: none;
    /* font-size: var(--body-size);
  line-height: var(--body-lh); */
}
.completed_animation {
    display: flex;
    min-height: 200px;

    justify-content: center;
    align-items: center;
}

.arrow {
    position: relative;
    z-index: 1;
    left: calc(50% - 16px);

    width: 32px;
    height: 32px;
    margin-top: -14px;
    margin-bottom: -14px;
    padding: 4px;

    border: 4px solid var(--header-color);
    border-radius: var(--border-radius);
    background-color: var(--dark2);
}

.action_buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.view_etherscan {
    width: 100%;
    padding: 12px 16px;
    font-size: var(--header-size);

    line-height: var(--header1-lh);
    background: var(--accent1);

    text-decoration: none;
    color: var(--text1);
    border-radius: var(--border-radius);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: var(--transition);
}

.view_etherscan:hover,
.view_etherscan:focus-visible {
    background: var(--dark2);
    color: var(--accent1) !important;
    border: 1px solid var(--accent1);
    border-radius: var(--border-radius);
}

.view_etherscan:hover > svg {
    color: var(--accent1) !important;
}

.bypass_buttons a,
.bypass_buttons button {
    padding: 3px 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
