.range_width_container {
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--content-bg);
    transition: var(--transition);
}

.title {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.range_width_content {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
}
.range_width_input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12%;
}

.range_width_input span {
    color: rgb(218, 214, 214);
}

.range_width_title {
    display: flex;
    justify-content: flex-start;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    margin-top: 10px;
}

.percentage_container {
    display: flex;
    width: 100%;
    padding: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.percentage_amount {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--text2);
    margin-right: 8px;
}

.percentage_options {
    display: flex;
    padding: 0 0rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.matching_percentage_button,
.percentage_option_buttons {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    overflow: hidden;
    margin: 4px !important;
    background: var(--dark2);
    padding: 0.5rem 1%;
    cursor: pointer;
    color: var(--text2);
    border: none;
    border-radius: 50px;
    padding: 5px 7px;
    background-color: var(--dark2);
    transition: var(--transition);
    border: 1px solid transparent;
}

.percentage_option_buttons:hover {
    color: var(--accent1);
    border: 1px solid var(--accent1);
}

.percentage_option_buttons:focus-visible,
.percentage_input:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.matching_percentage_button {
    background-color: var(--accent1);
    color: var(--dark1);
}
