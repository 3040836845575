.modal_container {
    width: 400px;
    min-height: 300px;
}

.modal_footer {
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.5rem;
}

.modal_content {
    padding: 0 20px;
}

.confirm_range_modal_container {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--dark1);
}

/* range header */
.position_display {
    display: flex;
    padding: 0.5rem 0;

    justify-content: space-between;
    align-items: center;
}
.token_display {
    display: flex;

    align-items: center;
    gap: 10px;
}

.token_symbol {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);

    font-weight: 300;
    color: var(--text1);
}
.fee_tier {
    font-size: 14px;
    font-weight: 500;

    padding: 5px;

    background-color: var(--header-color);
}

.tokens {
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 8px;
}

.tokens img {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    background: white;
}

.range_display {
    font-size: 14px;
    font-weight: 500;

    display: inline-flex;

    padding: 4px 6px;

    color: rgb(255, 255, 255);
    border: unset;
    border-radius: 0.5rem;
    background-color: var(--content-bg);

    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

/* fee tier display */
.fee_tier_display {
    padding: 1rem;

    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--dark2);
}
.fee_tier_container {
    display: grid;

    grid-auto-rows: auto;
    row-gap: 12px;
}

.detail_line div {
    display: flex;

    align-items: center;
}

.detail_line {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);

    display: flex;

    align-items: center;
    justify-content: space-between;
}

.detail_line div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.divider {
    background: var(--dark1);
    height: 1px;
    width: 100%;
}
