.main_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

.actions_menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.reposition_button {
    cursor: pointer;
    height: 23px;

    line-height: var(--body-lh);
    font-size: var(--body-size);
    white-space: nowrap;
    padding: 5px 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--accent1);
    border: 1px solid var(--accent1);
    border-radius: 50px;

    transition: var(--transition);
    z-index: 2;
}

.reposition_button:hover {
    color: var(--dark1);
    background: var(--accent1);
}
.dropdown_menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.dropdown_menu button {
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text1);
    outline: none;
}

.dropdown_menu button:hover {
    color: var(--accent1);
}

.actions_menu {
    display: flex;
    justify-content: flex-end;
}

.menu_column {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    gap: 4px;
}

.menu_column button {
    border: 1px solid transparent;
}

.dropdown_button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}
.dropdown_button:focus-visible {
    border: 1px solid var(--accent1);
}
.dropdown_button path {
    color: var(--dark3);
}
.dropdown_button:hover {
    cursor: pointer;
}

.dropdown_button:hover path,
.dropdown_button:hover circle {
    color: var(--accent1);
    transition: var(--transition);
}
.dropdown_wrapper,
.dropdown_wrapper_active {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    /* transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    right: 30px;
    z-index: 5;
}

.dropdown_wrapper {
    top: 3px;

    opacity: 0;
    pointer-events: none;
}

.dropdown_wrapper_active {
    opacity: 1;
    top: 0;
    pointer-events: auto;
}
