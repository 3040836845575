.repositionContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.reposition_content {
    padding: 0 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

/* Switch buttons */
.reposition_toggle_container {
    /* width: 100%; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: var(--dark2);
    border-radius: var(--border-radius);
    outline: none;
    /* max-width: 200px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}
.reposition_toggle_container a {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    padding: 4px 1.5rem;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    border: none;
    outline: none;
    text-align: center;
    border-radius: var(--border-radius);
}
.reposition_toggle_container a:hover {
    color: var(--text1);
}
.active_button_toggle {
    color: rgb(0, 0, 0);
    background: var(--accent1);
}

.non_active_button_toggle {
    color: var(--text2);
    background-color: transparent;
}

.view_etherscan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    font-size: var(--body-size);
    line-height: var(--body-lh);

    color: var(--accent1);
    cursor: pointer;
    padding: 8px 0;
}

.button_container {
    margin: auto 2rem;
}
