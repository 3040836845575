.removal_pending {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    gap: 2px;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.formatted_error{
    word-break: break-all;
    height: 50px;
    overflow-y: scroll;
}

.formatted_error_container{

    display: flex;
    flex-direction: column;
    gap: 8px;

}

.copy_error{
    width: 80%;
    background: none;
    margin: 0 auto;
    cursor: pointer;
    outline: none;
    display: flex;
padding: 4px 8px;
justify-content: space-between;
align-items: center;
border-radius: 4px;
border: 1px solid var(--text2);
align-self: stretch;
color: var(--text2);

}
.copy_error:hover{
    border: 1px solid var(--accent4);
    color: var(--accent4);


}