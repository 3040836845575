.outside_modal {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(1, 0, 16, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    transition: var(--transition);
}

.modal_container {
    padding: 1px;
    background: var(--title-gradient);
    height: auto;
    min-width: 347px;
    border-radius: var(--border-radius);
}
.modal {
    display: flex;
    flex-direction: column;

    color: white;

    background-color: var(--dark1);

    align-items: center;
    border-radius: var(--border-radius);
    height: auto;
}

.modal_header {
    font-size: 14px;
    font-weight: 500;

    display: flex;
    gap: 8px;

    width: 100%;
    padding: 10px 20px;
    height: 56px;
    /* padding: 10px 20px; */

    letter-spacing: 1px;
    text-transform: capitalize;

    /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */

    color: var(--text-grey-white);

    align-items: center;
    justify-content: center;
}

.modal_header img {
    max-width: 100%;
    margin: 0 10px;
}

.close_button {
    width: 15px;

    cursor: pointer;

    color: white;
}

.modal_content {
    width: 100%;
    padding: 1rem;
    height: auto;
}

.content_title {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--header2-size);
    line-height: 22.5px;
    font-weight: 300;
    text-align: center;
}
