.pool_card {
    cursor: pointer;
    position: relative;
    list-style: none;
    border-radius: var(--border-radius);
}

.main_container {
    width: 270px;
    height: 120px;
    border-radius: var(--border-radius);
    background: var(--dark2);
    padding: 8px;
    z-index: 1;
    cursor: pointer;
    position: relative;
    transition: var(--transition);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.pool_card:hover,
.pool_card:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.column {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
}

.token_images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.tokens_name {
    font-family: var(--font-logo);
    font-weight: 100;
    font-size: var(--header-size);
    line-height: 22px;
    display: flex;
    justify-content: center;
    color: var(--text1);
}

.pool_price_change {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.pool_price_title {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    color: var(--text1);
    margin-left: 2px;
}

.row_title {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    /* identical to box height */

    display: flex;
    justify-content: flex-start;
    text-align: flex-start;
    align-items: flex-start;

    /* text/grey light */

    color: var(--text2);
}

.apr {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;

    background: var(--apr-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.vol {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;

    /* text/white */

    color: var(--text1);
}
.change_positive {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: 22px;
    letter-spacing: -0.02em;

    /* other/positive */

    color: var(--positive);
}
.change_negative {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: 22px;
    letter-spacing: -0.02em;

    /* other/positive */

    color: var(--negative);
}
.price {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;

    letter-spacing: -0.02em;
    color: var(--text1);
    font-family: sans-serif;
    /* font-family: var(--mono); */
}

.full_width {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.full_width_center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .main_container {
        background-color: transparent;

        background: var(--dark2);
    }

    .pool_card:hover,
    .pool_card:focus-visible {
        box-shadow: none;
    }

    .column:first-of-type {
        align-items: flex-start;
    }
}
