.tx_details_container {
    width: auto;
    max-width: 1000px;

    transition: var(--transition);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 1rem;
}

.main_outer_container {
    background: url(../../../assets/images/backgrounds/detailsCard.png)
        no-repeat;
    background-position: center bottom 40px;
}

.main_content {
    cursor: default;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    padding: 1rem 2rem;

    /* width: 800px; */
    /* align-items: center; */
}
.control_display_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.4rem 0;
}

.left_container {
    background: rgba(23, 29, 39, 0.3);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    margin-top: auto;
    margin-bottom: auto;
}

.right_container {
    /* width: 450px; */
    background: rgba(23, 29, 39, 0.3);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
}

.ambi_copyright {
    font-family: var(--font-logo);
    display: flex;
    justify-content: center;

    font-weight: 200;
    font-size: var(--header1-size);
    line-height: 30px;
    color: var(--text1);
    padding: 4px 0;
    cursor: default;
}

@media only screen and (max-width:600px) {
    .main_content {

        display: grid;
        grid-template-rows: 1fr 0.9fr;
    }
}

@media only screen and (min-width: 768px) {
    .outer_container {
        min-height: 562px;
        height: auto;
        width: 1000px;

        display: grid;
        grid-template-rows: 60px auto;
        gap: 1rem;
        padding-top: 1rem;
    }

    .main_outer_container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }
    .main_content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 244px auto;
        gap: 1rem;

        animation: fadeIn ease 1s;
        -webkit-animation: fadeIn ease 1s;
        -moz-animation: fadeIn ease 1s;
        -o-animation: fadeIn ease 1s;
        -ms-animation: fadeIn ease 1s;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
