.annotation-line > .priceLine {
    stroke: rgba(97, 71, 247, 0.8);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}


.priceLine {
    stroke: rgba(97, 71, 247, 0.8);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}

.annotation-line > .limitPriceLine {
    stroke: rgba(235, 235, 255);
    stroke-width: 1.5;
    stroke-dasharray: 4 3;
    border: 2px dashed rgba(19, 19, 134, 0.8);
}


.annotation-line > .verticalLine {
    
    stroke: #61646F;
    stroke-width: 1.5;
    stroke-dasharray: 8 8;
    border: 1px solid #61646F   
}

.transaction_details_graph_placeholder {
    background: none no-repeat;
    background-position: center 90%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    width: 100%;
    height: 100%;
}

.transaction_details_graph svg:hover {
    color: var(--accent1);
    cursor: default;
}
