.notfound_container {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.content a {
    cursor: pointer;
    padding: 0 0.3rem;
    font-size: var(--header1-size);
    line-height: var(--header2-lh);
    background: var(--title-gradient);
    font-family: var(--font-logo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
