.tab_window {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
}

.tab_nav {
    padding-right: 8px;
    border-radius: var(--border-radius);
}
.main_tab_content {
    user-select: none;
    margin-top: 6px;
    flex: 1;
    overflow: hidden;
}

.tab_ul,
.tab_ul li,
.tab_ul_left {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.tab_ul {
    display: flex;
    width: 100%;
}

.tab_ul li,
.menu_content li,
.tab_ul_left li {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    padding: 10px 0;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 0;
    position: relative;
    user-select: none;
    list-style: none;
    color: var(--text2);
    font-weight: 500;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.label_button {
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
}

.tab_ul li.selected,
.menu_content li.selected,
.tab_ul_left li.selected {
    color: var(--accent1);
}

.tab_ul_left li {
    max-width: 25%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tab_ul li:hover,
.tab_ul_left li:hover,
.menu_content li:hover,
.selected {
    color: var(--accent1);
    transition: var(--transition);
}

.selected button,
.non_selected button {
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 19px;
}

.selected button {
    color: var(--accent1);
    font-size: 15px;
    white-space: nowrap;
}

.non_selected button {
    color: var(--text3);
    font-size: 13px;
}

.non_selected:hover button {
    color: var(--accent1);
}

.underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent1);
}
.tab_with_option_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.tab_ul_left {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.tab_icon_container {
    background: var(--dark3);
    padding: 4px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.tab_icon_container:hover {
    cursor: pointer;
    background: var(--dark2);
    transition: var(--transition);
}
.tab_icon {
    display: flex;
}

.item_label {
    display: none;
}

.item_label:focus-visible {
    border-bottom: 1px solid var(--text1);
}
@media (min-width: 800px) {
    .tab_icon_container {
        display: none;
    }
}

@media (min-width: 500px) {
    .item_label {
        display: flex;

        background: transparent;
        outline: none;
        border: none;

        cursor: pointer;
    }

    .selected {
        color: var(--accent1);
    }

    .tab_ul li,
    .menu_content li,
    .tab_ul_left li {
        padding: 8px 10px;
    }

    .justify_content_flex_start li {
        justify-content: flex-start;
    }

    .justify_content_center li {
        justify-content: center;
    }

    .tab_ul_left {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tap_option_right {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-items: baseline;
        max-height: 44px;
    }
}
