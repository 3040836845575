.main_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.price_info_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* TOKEN PAIR DETAILS */
.token_pair_details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.token_pair_details p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}

.token_pair_images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.align_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.earned_container,
.price_status_content,
.value_content {
    padding: 8px;
}
.value_content,
.earned_container div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.earned_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.earned_container,
.earned_container section {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.earned_container p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.price_status_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
}
.price_status_content section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.price_status_content p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.price_status_content h2 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.low_range {
    color: var(--accent5);
}

.high_range {
    color: var(--accent1);
}

.ambi_info_text {
    text-align: center;
    background: var(--dark2);
    padding: 4px;
    border-radius: var(--border-radius);
    text-transform: none;
}
/* .order_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2)
} */
.divider {
    width: 100%;
    height: 1px;
    background: var(--dark3);
    margin: 4px 0;
}
@media only screen and (min-width: 768px) {
    .price_info_container,
    .value_content,
    .buy_content,
    .sell_content,
    .price_status_content {
        gap: 1rem;
    }
}
