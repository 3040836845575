.wallet_confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* padding: 20px */
}
.wallet_confirm h2,
.wallet_confirm div {
    padding: 10px 0;
}

.wallet_confirm h2 {
    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}
.wallet_confirm div {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
