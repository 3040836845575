.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;

    margin: 0 16px;
}

.token_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
}
