.window {
    position: relative;

    color: var(--text2);
    border-radius: var(--border-radius);

    background-color: var(--accent1);
    background: var(--accent1);
}

.main_content {
    background-color: transparent;
    border-radius: var(--border-radius);

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.swap_main_content {
    box-shadow: var(--gradient-box-shadow);
}

.swap_bg {
    background: var(--dark1);
}
.circle,
.circle2 {
    display: none;
}

.no_background {
    background: transparent;
    margin-top: 0;
}

.main_content:hover,
.main_content:focus-within {
    transition: var(--transition);
}

.swap_route {
    background: var(--accent1);
    padding: 1px;
    border-radius: var(--border-radius);
    box-shadow: var(--swap-box-shadow);
}

/* media queries */

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }

    .customWidthAuto {
        max-width: 1240px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
    .customWidthAuto {
        max-width: auto;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .customWidthAuto {
        max-width: auto;
    }
    .no_background:hover,
    .no_background:focus-within {
        box-shadow: none;
    }

    .swap_main_content {
        padding: 24px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 420px;
    }
    .swap_route {
        min-width: 430px;
        max-width: 440px;
    }
    .customWidth_container {
        max-width: 640px;
    }

    .customWidthAuto {
        max-width: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .main_content {
        background-color: var(--dark1);

        /* width: 90%;
        margin: 0 auto; */
    }

    .swap_route,
    .no_background {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) {
    .main_content {
        overflow-y: scroll;
    }

    .swap_route,
    .no_background {
        width: 95%;
        overflow-y: scroll;
    }
}
