.close_button {
    cursor: pointer !important;
}

.header_container {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

   
    padding: 1rem;
    border-radius: var(--border-radius);
}

.header_container svg {
    cursor: pointer;
    color: var(--text2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_container h2 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    display: flex;
    letter-spacing: -0.02em;
    color: var(--text1);
}

.back_button {
    position: absolute;
    padding: 1rem;
    left: 0;
}

.close_button {
    position: absolute;
    padding: 1rem;
    right: 0;
}
