.removal_pending {
    width: 100%;
    height: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    gap: 1rem;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    /* padding: 20px; */
}

.animation_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.animation_container h2 {
    color: var(--text1);

    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.animation_container p {
    color: var(--text2);

    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.removal_pending_bypass button {
    padding: 3px 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
