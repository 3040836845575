.main_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem 0;
}

.price_info_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.info_container {
    width: 100%;

    /* Note: backdrop-filter has minimal browser support */
    /* background: red; */
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: var(--border-radius);
    padding: 0.6rem;

    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.divider {
    height: 1px;
    margin: 1rem 0;
}
.info_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.125em;
    text-transform: capitalize;
}

.info_text img {
    width: 15px;
}
.graph_image_container {
    width: 48%;
}
.graph_image_container img {
    width: 100%;
}
.min_max_price {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}

.min_max_price p {
    font-weight: 300;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.min_max_content {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: center;
    align-items: center;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
.min_price,
.max_price {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;
}

.max_price {
    color: var(--accent1);
}
.min_price {
    color: var(--accent5);
}

/* TOKEN PAIR DETAILS */
.token_pair_details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.token_pair_details p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}
.tx_details {
    /* cursor: pointer; */
    display: flex;
    flex-direction: column;

    padding: 0 1rem;

    /* gap: 8px; */
}
.tx_details div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
.tx_details p {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    font-weight: 300;
}

.token_pair_images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.align_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

@media only screen and (min-width: 768px) {
    .price_info_container,
    .main_container {
        gap: 1rem;
    }
}
