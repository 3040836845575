.home_wallpaper_mobile {
    background: linear-gradient(
            to bottom,
            rgba(13, 17, 23, 0),
            rgba(13, 17, 23, 0.1) 80%,
            rgba(13, 17, 23, 0.4) 85%,
            rgba(13, 17, 23, 0.7),
            rgba(13, 17, 23, 0.9),
            rgba(13, 17, 23, 1)
        ),
        url('/src/assets/images/home/home_wallpaper_compressed.png') no-repeat;
}

.home_wallpaper {
    background: linear-gradient(
            180deg,
            var(--dark1) 0%,
            var(--dark1) 0%,
            rgba(0, 0, 0, 0) 0%,
            var(--dark1) 100%
        ),
        url('/src/assets/images/home/home_wallpaper_compressed.png') no-repeat
            center center;
}
.home2 {
    background: url(/src/assets/images/home/home2.png) no-repeat;
}
.home3 {
    background: url(/src/assets/images/home/home3.png) no-repeat;
}
.home4 {
    background: url(/src/assets/images/home/home4.png) no-repeat;
}

.background {
    background: url('/src/assets/images/backgrounds/background.png') no-repeat
        center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ambient_blast_logo {
    font-size: 50px;
    font-family: 'Lexend Exa';
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    background: linear-gradient(
        90deg,
        #7371fc 0.01%,
        #cdc1ff 49.48%,
        #7371fc 99.99%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
