.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.container header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--text2);
}

.container header div {
    cursor: pointer;
}

.global_popup,
.global_popup_active {
    max-width: 250px;
    padding: 20px;
    width: auto;
    height: auto;
    position: absolute;
    background: var(--dark2);
    border-radius: 15px;
    box-shadow: 0px 35px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    right: 30px;
    z-index: 9999999;

    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.popup_left {
    left: 30px;
}

.popup_right {
    right: 30px;
}

.popup_center {
    left: 0;
    right: 0;
}
.global_popup {
    bottom: 10px;

    opacity: 0;
    pointer-events: none;
}

.global_popup_active {
    opacity: 1;

    bottom: 85px;
    pointer-events: auto;
}
