.token_icon {
    clip-path: circle();
}

.token_logo_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
