.chart_overlay_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.chart_tooltips {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0;
    height: 40px;
}

.current_data_info {
    padding: 0.3rem 0 0 0.3rem;

    /* extra small */

    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--mini-lh);

    /* text/grey light */

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    color: var(--text2);
}

.settings_container:not(:last-child) {
    border-right: var(--grey-light-border);
}

.active_selected_button,
.non_active_selected_button {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}
.settings_container button:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

@media screen and (min-width: 768px) {
    .active_selected_button:hover,
    .non_active_selected_button:hover {
        /* background: var(--title-gradient); */
        color: var(--text1);
    }
}

.active_selected_button {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.non_active_selected_button {
    color: var(--text2);
}

.border_left {
    text-decoration: underline;
    text-underline-offset: 3px;
}
