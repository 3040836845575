.menu {
    position: relative;
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.menu ul,
.menu li {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.menu ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--dark3);
}
.main_container {
    position: absolute;
    width: 222px;
    top: 45px;
    right: 0;
    z-index: 999;
}

.main_container li {
    display: flex;

    color: var(--text1);
}

.main_button {
    -webkit-appearance: button;
    color: var(--text1);
    width: 222px;
    padding: 2px 10px 2px 4px;
    height: 40px;
    border-radius: var(--border-radius);
    background: var(--dark3);
    border: none;

    cursor: pointer;

    /* text-align: left; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.main_button path {
    fill: var(--text1);
}

.skin_item_container {
    /* padding: 10px 1.3rem; */
    cursor: pointer;
    padding: 10px 0;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    /* background: darkblue; */
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);
}

.skin_color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}
