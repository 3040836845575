.main_layout_chart {
    transition: all 600ms ease-in-out;
    width: 100%;
    height: 100%;
    color: var(--text2);
    position: relative;
}

text {
    fill: var(--text2);
}

text.market {
    font-size: 13px;
    font-weight: bold;
    fill: black;
}

d3fc-canvas,
d3fc-svg {
    overflow: hidden;
    grid-column: 3;
    grid-row: 3;
}

.chart_grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1% auto 1fr auto minmax(1em, max-content);
    grid-template-rows: minmax(0em, max-content) auto 1fr auto;
    transition: all 600ms ease-in-out;
}

text.crossHairText {
    font-size: 13px;
    font-weight: bold;
}

text.blur {
    filter: blur(7px);
}

text.startDate {
    font-size: 13px;
    font-weight: bolder;
}

text.y_axis {
    font-size: 13px;
    font-weight: bold;
}

.domain,
.tick>path,
.gridline-y,
.gridline-x {
    stroke: var(--text2);
    stroke-opacity: 0.1;
}

.annotation-line>.line {
    stroke: var(--text2);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--text2);
}

.annotation-line>.order_sell {
    stroke: var(--accent2);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--accent2);
}

.annotation-line>.order_buy {
    stroke: var(--accent1);
    stroke-width: 3;
    stroke-dasharray: 16 16;
    border: 2px dashed var(--accent1);
}

.annotation-line > .marketLine {
    stroke: var(--text2);
    stroke-width: 1;
    stroke-dasharray: 4 2;
    stroke-opacity: 0.4;
    border: 2px dashed var(--text2);
}

.annotation-line>.ghostline {
    stroke: var(--text2);
    stroke-width: 1;
    stroke-opacity: 0.5;
    border: 1px var(--text2);
}

.candlestick .down {
    background-color: var(--accent1);
}

.popup {
    position: absolute;
    text-align: center;
    cursor: pointer;
    /* pointer-events: none; */

    width: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: rgba(10, 10, 19, 0.47);
    padding: 6px;
    font-size: large;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.liqTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.xAxisTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

.liqTooltip {
    position: absolute;
    text-align: center;
    align-items: center;

    background: rgba(10, 10, 19, 0.47);
    padding: 3px;
    font-size: small;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
}

div.tooltip {
    position: absolute;
    pointer-events: none;
    background: rgba(10, 10, 19, 0.35);
    padding: 8px;
    width: 15%;
    box-shadow: 0 8px 24px 0 rgba(31, 38, 135, 0.35);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
    -webkit-transition: 2s all ease-in-out;
    -moz-transition: 2s all ease-in-out;
    -o-transition: 2s all ease-in-out;
    transition: 0.8s all ease-in-out;
}

@keyframes hideAnimation {
    to {
        visibility: hidden;
    }
}

.kHKQUR {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 6px;
}

.eJnjNO {
    box-sizing: border-box;
    margin: 1px;
    min-width: 0px;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: fit-content;
}

.block {
    display: flex;
    flex-direction: row;
}

.value_title {
    flex-grow: 8 !important;
    font-weight: 500;
    font-size: var(--body-size);
}

.value {
    font-weight: 500;
    font-size: var(--body-size);
}

.crosshair {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-dasharray: 0.6 0.6;
    stroke-opacity: 1;
}

.indicatorLine {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-dasharray: 0.6 0.6;
    stroke-opacity: 1;
}

.highlightedPrice {
    stroke: rgb(255, 255, 255);
    stroke-width: 0.5px;
    stroke-opacity: 1;
}

label {
    color: rgba(204, 204, 204, 0.63);
    padding-left: 7px;
    padding-top: 2px;
    font-size: var(--body-size);
}

.orderHistoryTooltip-enter {
    opacity: 0;
    transform: translateX(100%);
}

.orderHistoryTooltip-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.orderHistoryTooltip-exit {
    opacity: 1;
    transform: translateX(0);
}

.orderHistoryTooltip-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.orderHistoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #f0f0f8;
    font-size: 16px;
}

.lastCandleDiv {
    position: fixed;
    text-align: center;
    cursor: pointer;
    pointer-events: none;

    background: rgba(10, 10, 19, 0.7);
    padding: 6px;
    font-size: medium;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: var(--border-radius);
    width: 260px;
    z-index: 999999;
}

.lastCandleTooltip-enter {
    opacity: 0;
    transform: translateX(100%);
}

.lastCandleTooltip-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.lastCandleTooltip-exit {
    opacity: 1;
    transform: translateX(0);
}

.lastCandleTooltip-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.scroll {
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.47);
}

#d3fc_group {
    height: 100%;
    /* padding: 1%; */
    display: block;
}

@media (max-width: 1200px) and (min-width: 700px) {
    .popup {
        top: 50% !important;
        padding: 5px;
        font-size: large;
    }
}

@media only screen and (max-width: 1325px) {
    .popup {
        font-size: medium;
        top: 66%;
    }
}

@media only screen and (max-width: 700px) {
    .popup {
        font-size: medium;
        width: 80%;
        top: 49%;
    }
}

@media (max-width: 800px) and (min-width: 500px) {
    .chart_grid {
        height: 80%;
    }
}

@media (max-width: 1200px) and (min-width: 800px) {
    .chart_grid {
        height: 85%;
    }
}

/*  SUBCHART  */

#fee_rate_chart,
#tvl_chart {
    display: grid;
    grid-template-rows: 0px auto 1fr auto;
    grid-template-columns: 1% auto 1fr auto minmax(1em, max-content);
    height: 20%;
    min-height: 3rem;
}

/* SUBCHART END */
