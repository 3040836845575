.card {
    position: relative;
    padding: 1px;
    background: var(--title-gradient);
    border: 4px;
}

.card img {
    width: 100%;
    display: block;

    border-radius: var(--border-radius);
}

.card .front {
    transform: rotateY(90deg);
    position: absolute;
    transition: all ease-in 0.2s;
}

.flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}
.card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0s;
}
