.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* 56px is the header height */
    background-color: var(--dark2);
    height: calc(100vh - 56px);
}
