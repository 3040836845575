.container {
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: var(--border-radius);
    
}

.no_modal_container{
   
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: var(--dark1);
    border-radius: var(--border-radius);

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.container h2, .no_modal_container h2 {
    font-size: var(--body-size);
    line-height: var(--header2-lh);
    color: var(--text2);
}

.container input, .no_modal_container input {
    width: 100%;
    height: 30px;
    background: var(--dark2);
    outline: none;
    border: none;
    padding: 0 4px;
    margin-bottom: 8px;
    border-radius: var(--border-radius);
}

.scrollable_container {
    max-height: 70vh;
    overflow-y: scroll;
}

.scrollable_container::-webkit-scrollbar {
    width: 3px;
}

.token_not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input_control_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.input_control_container button {
    background: transparent;
    padding: 6px 0;
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
    border: none;
    outline: none;
    cursor: pointer;
    transition: var(--transition);
}
.clearButton {
    transform: translateY(-16%);
}

.input_control_container button:hover {
    color: var(--text1);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.weth_warning {
    display: flex;
    flex-direction: row;
}

.weth_warning > button {
    min-width: 20%;
    font-size: 30px;
}

.weth_text {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    text-align: center;
    color: var(--text1);
    padding: 1rem;
}

.header{
    height: 41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--header1-size);
    line-height: var(--header1-lh);
    font-weight: 200;
    color: var(--text1);
}
@media only screen and (min-width: 768px) {
    .container {
        width: 448px;
    }

    .no_modal_container{
        width: 772px;
    }
}
