.main_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: var(--transition);
    background: transparent;
    outline: none;
    border: none;
    border-radius: var(--border-radius);
    padding: 0 8px;
}

.main_container:hover,
.main_container:focus-visible {
    background: var(--dark2);

    cursor: pointer;
}

.main_container:focus-visible {
    border-left: 1px solid var(--text1);
}

.modal_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 5px;
    border-radius: var(--border-radius);
    transition: var(--transition);
}

.left_side_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.star_icon,
.close_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);
}

.close_icon_svg:hover {
    color: var(--accent5);
}

.modal_tokens_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: var(--transition);
    cursor: pointer;
    gap: 1rem;
}

.star_svg {
    cursor: pointer;
    transition: var(--transition);
}

.star_svg:hover {
    fill: var(--text2);
    cursor: pointer;
}

.modal_tokens_amount {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;
}

.modal_token_symbol,
.modal_tokens_amount {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    /* font-weight: 400; */
}
.modal_token_name {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.delete_container {
    padding: 0 10px;
    position: absolute;
    border-radius: var(--border-radius);
    bottom: 0;
    right: 0;
    background-color: var(--dark2);
    overflow: hidden;
    height: 100%;
    transition: var(--transition);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--body-size);
    font-size: 13px;
    line-height: var(--body-lh);
}

.delete_active {
    left: 100%;
    width: 0;
}

.delete_inactive {
    width: 100%;
    left: 0;
}

.toggle_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.liqtype_buttons_container {
    font-size: 0;
}

.liqtype_buttons_container button {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    cursor: pointer;
    transition: var(--transition);
    padding: 5px 12px;
    border: none;
    border: 1px solid var(--accent5);
    outline: none;
}

.liqtype_buttons_container button:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.liqtype_buttons_container button:nth-child(2) {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.liqtype_buttons_container button:hover {
    color: var(--text2);
}

.name_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.active_button {
    color: rgb(0, 0, 0);
    background: var(--title-gradient);
}

.non_active_button {
    color: white;
    background-color: transparent;
}

.confirm {
    cursor: pointer;
    transition: all var(--animation-speed) ease;
}

.primary_style {
    color: var(--accent5);
}

.danger_style {
    color: var(--negative);
}

.confirm:hover {
    transform: scale(1.03);
}

.token_list_data {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    width: 100%;
    text-align: end;
}
