.gradient_text {
    background: var(--title-gradient);
    font-family: var(--font-logo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* order table */

.color_white {
    color: var(--text1);
}

.base_color {
    color: var(--text1);
}

.username_base_color {
    color: var(--text1);
    font-family: var(--roboto);
}

.primary_color {
    color: var(--accent1);
}

.order_sell {
    color: var(--accent1);
}
.owned_tx_contrast {
    color: var(--accent2);
    font-weight: 300;
}

.order_buy {
    color: var(--accent5);
}

/* transactions */
.add_style {
    color: var(--accent3);
    border-color: var(--accent3) !important;
}
.claim_style,
.remove_style {
    color: var(--accent4);
    border-color: var(--accent4) !important;
}
.buy_style {
    color: var(--accent5);
    border-color: var(--accent5) !important;
}
.sell_style {
    color: var(--accent1);
    border-color: var(--accent1) !important;
}

/* apy */

.apy_positive {
    color: var(--text1);
}

.apy_negative {
    color: var(--text1);
}

/* sort */

.active_sort {
    background: var(--dark2);
}



.hide_scrollvar::-webkit-scrollbar {
    display: none;
}

.emoji-picker-react .emoji-group:before,
.emoji-picker-react input.emoji-search {
    color: var(--text2) !important;
    background: var(--dark2) !important;
}

aside.emoji-picker-react {
    background: var(--dark2) !important;
    box-shadow: 0 5px 10px var(--dark1) !important;
    border: 1px solid var(--border) !important;
}

.emoji-picker-react .emoji-categories button {
    filter: invert(100%);
}

.MuiTooltip-popper {
    z-index: 9999999 !important;
}

.introjs-tooltip {
    background: var(--dark2) !important;
    /* background: white !important; */
}
.introjs-tooltip-header {
    color: var(--text1);
    /* color: black; */
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
}
.introjs-tooltip-title {
    color: var(--text2) !important;
    /* color: black !important; */
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.introjs-button {
    background: var(--dark2) !important;

    outline: none !important;
    border: none !important;
    color: var(--text2) !important;
    text-shadow: none !important;
    transition: all var(--animation-speed) ease-in-out !important;
    padding: 0 1rem !important;
}
.introjs-tooltipbuttons a {
    /* font-size: 1.2rem; */
    color: var(--accent5);
    /* font-weight: 700; */
}

.introjs-button:hover {
    color: var(--text1) !important;
    border: none !important;
    outline: none !important;
}
.introjs-tooltipbuttons {
    position: fixed;
    right: 51.14px;
    bottom: 48px;
    padding: 4px 16px !important;

    box-shadow: var(--glow-box-shadow);
    border-radius: var(--border-radius);
}

/* .MuiPagination-root{
        
        background: red !important;
    } */
.MuiPagination-ul {
    background: var(--dark2);
    border-radius: var(--border-radius) !important;
    flex-wrap: nowrap !important;
}

.MuiPaginationItem-root {
    color: var(--text2) !important;
    outline: 1px solid transparent !important;
}

.MuiPaginationItem-root:hover {
    color: var(--text1) !important;
}
.Mui-selected.MuiPaginationItem-root {
    color: var(--text1) !important;
    background: transparent !important;
}

.MuiPaginationItem-root {
    display: inline-flex !important;
}

.css-1idz92c-MuiCircularProgress-svg {
    color: var(--title-gradient) !important;
}
.css-9tmuzo-MuiStepper-root{
    gap: 4px !important;
    font-size: 18px;

  
}

.css-14sza3e-MuiStepLabel-root {
    padding: 0 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: var(--accent1) !important
}


.icon_hover:hover {
    color: var(--accent1) !important;
    cursor: pointer;
}
#current_row_scroll {
    height: 100%; 
    box-sizing: border-box; 
}

@media (max-width: 500px) {
    #current_row_scroll {
        overflow-y: scroll;
        height: 45dvh;
    }
    .css-th9gsg-MuiPaper-root-MuiAlert-root{
        margin: 0 auto !important;
        text-wrap:wrap !important;
        word-wrap: break-word !important;
        max-width: 330px !important;
    }
  }
@media only screen and (min-width: 1280px) {
    .top-pools-analytics-table td,
    .top-pools-analytics-table th {
        min-width: 180px;
    }
}
@media only screen and (max-width: 768px) {
   .mobile_auto_height{
    height: auto !important;
   }
}
