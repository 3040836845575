.main {
    z-index: 999999;
}
.tutorial_navigation_container {
    z-index: 999999;
    position: absolute;
    bottom: 30px;
    right: 90px;
    width: 138.86px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;

    /* dark/secondary */

    background: var(--dark2);
    /* glow/standard */

    box-shadow: 0px 0px 100px rgba(205, 193, 255, 0.3),
        0px 0px 60px rgba(205, 193, 255, 0.3),
        0px 0px 20px rgba(205, 193, 255, 0.3),
        0px 0px 12px rgba(205, 193, 255, 0.3),
        0px 0px 6px rgba(205, 193, 255, 0.3),
        0px 0px 3px rgba(205, 193, 255, 0.3);
    border-radius: var(--border-radius);
}

.tutorial_navigation_container button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
}
