.swap_page_container {
    background: url(../../assets/images/backgrounds/swap_bg.png) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 56px);

}

.swap_page_container > section {
    margin-top: 64px;
    height: auto;
}
