.main_content_container {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    gap: 16px;
}

.info_container {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    gap: 8px;
}
