.removal_pending {
    width: 100%;
    height: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    gap: 1rem;

    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.animation_container {
    min-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
