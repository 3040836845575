.footer_item_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 8px;
    cursor: pointer;
    padding: 4px;
    border-radius: var(--border-radius);
}

.footer_item_container:hover {
    cursor: pointer;
    background: var(--dark2);
    transition: var(--transition);
}

.footer_item_container h3 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    color: var(--text1);

    gap: 4px;
}

.footer_item_container p {
    font-weight: 300;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.mobile_version {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
    margin: 0 2rem;
}

.started_button {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    text-align: start;
    color: var(--accent1);
}

.mobile_version .footer_item_container h3 {
    font-weight: 300;

    font-size: var(--header2-size);
    line-height: var(--header2-lh);

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: center;
    text-align: center;
}

.started_button {
    text-align: center;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
}

.mobile_version .footer_item_container p {
    display: none;
}

.mobile_version .footer_item_container {
    text-align: center;
}

.container {
    width: 100%;
    background: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 40.39%,
            rgba(0, 0, 0, 0) 68.28%,
            #000000 100%
        ),
        url('../../assets/images/home/home3.png') no-repeat center center;
    mix-blend-mode: lighten;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top 30px right 0;
}

.content {
    display: flex;
    gap: 1rem;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 2rem 0;
}

.row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media only screen and (min-width: 768px) {
    .content {
        width: 1020px;

        flex-direction: row;

        justify-content: space-between;
        gap: 2rem;
        margin: 0 auto;
        align-items: flex-start;
        text-align: start;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        width: 100%;
    }

    .row {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .footer_item_container {
        width: 100%;
    }
}
