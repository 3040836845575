.wallet_row {
    display: grid;
    color: white;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--dark2);
}

.wallet_row p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    /* text-align: right; */

    height: 16px;
}

.value,
.amount {
    display: flex;
    justify-content: flex-end;
    margin: 0 5px;
}

.token {
    margin-left: 30px;
}
