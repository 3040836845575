.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: default;
    height: 60px;
}

.logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 35px;
    margin-left: 18px;
}

.logo_text {
    width: 176px;
    margin-left: 10px;
}
.settings_control {
    display: flex;
    flex-direction: row;

    gap: 8px;
    justify-content: center;
}

.settings_control div {
    cursor: pointer;
}

.info_button {
    cursor: pointer;
    outline: none;
    border: none;
    background: var(--accent1);
    border-radius: var(--border-radius);
    width: 70px;
    height: 25px;
}
