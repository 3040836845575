.volume_tvl_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.volume_tvl_container:not(:last-child) {
    border-right: var(--grey-light-border);
}

.active_selected_button,
.non_active_selected_button {
    background: transparent;
    transition: var(--transition);
    cursor: pointer;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    text-align: center;

    outline: none;
    border: none;
    padding: 1px 8px;
    position: relative;
}

.active_selected_button:hover,
.non_active_selected_button:hover {
    /* background: var(--title-gradient); */
    color: var(--text1);
}
.volume_tvl_container button:focus-visible {
    box-shadow: var(--glow-light-box-shadow);
}

.active_selected_button {
    width: 100%;
    height: 100%;
    color: var(--text1);
}

.non_active_selected_button {
    color: var(--text2);
}

.volume_tvl_fee_mobile_button {
    background: var(--dark2);
    color: var(--text1);
    padding: 2px 8px;
    border-radius: var(--border-radius);
    border: 1px solid var(--dark3);
}

.dropdown_menu {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.dropdown_wrapper,
.dropdown_wrapper_active {
    padding: 8px;

    position: absolute;
    background: var(--dark2);

    border-radius: var(--border-radius);
    box-shadow: 10px 35px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    z-index: 2;

    display: flex;
}
/* .dropdown_wrapper_active:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 98%;
    top: 0;
    border: 10px solid transparent;
    border-left: 10px solid var(--dark2);
} */

.dropdown_wrapper {
    top: 3px;

    opacity: 0;
    pointer-events: none;
    left: 0;
}

.dropdown_wrapper_active {
    opacity: 1;
    top: 30px;
    pointer-events: auto;
    left: 0;
    /* right: 5px; */
}
