.main_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
}

.wallet_container,
.gasless_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.wallet_container {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 33px;
}

.wallet_section,
.exchange_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    transition: var(--transition);
}

.wallet_amounts {
    display: flex;
    flex-direction: column;
}

.grey_logo img {
    filter: grayscale(100%);
}

.gasless_container {
    height: 15px;
}
.wallet_container_left {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.gasless_text {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
