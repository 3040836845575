.container {
    height: 100%;
}

.item_container {
    height: calc(100% - 24px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 8px;
}

.item_container::-webkit-scrollbar {
    width: 1px;
    display: none;
}
