.main_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem 0;
}

.price_info_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* TOKEN PAIR DETAILS */
.token_pair_details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    cursor: default;
}

.token_pair_details p {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    font-weight: 300;
}

.token_pair_images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.align_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.order_type,
.buy_content,
.sell_content,
.price_status_content {
    padding: 8px;
}
.order_type,
.buy_content,
.sell_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.buy_content p,
.sell_content p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.price_status_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.price_status_content section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.price_status_content p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.price_status_content h2 {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--accent5);
}
/* .order_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2)
} */

@media only screen and (min-width: 768px) {
    .price_info_container,
    .order_type,
    .buy_content,
    .sell_content,
    .price_status_content {
        gap: 1rem;
    }
}
