.container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--dark2);
    color: var(--text1);
    /* background: red; */
    z-index: 3;

    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 1rem 2rem;
}

.container,
.nft_modal_container img {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    cursor: pointer;
}
.no_image {
    width: 65px;
    height: 65px;
    background: var(--dark1);

    border-radius: 8px;
    cursor: pointer;
}

.content {
    display: flex;
    justify-content: center;
}

.settings_container {
    display: flex;
    flex-direction: column;

    width: 306px;
}

.settings_container section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content h3 {
    font-weight: 300;
    font-size: var(--header1-size);
    line-height: 30px;
    color: var(--text1);
}
.back_button {
    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.row input {
    padding: 2px 10px 2px 4px;
    height: 40px;
    width: 222px;

    border-radius: var(--border-radius);
    background: var(--dark2);
    outline: none;
    border: none;
    text-align: end;
    color: var(--text1);
}

.nft_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.settings_container .save_button {
    width: 100%;
    height: 25px;
    margin: 1rem 0;

    font-size: var(--header-size);

    line-height: var(--header1-lh);

    border-radius: var(--border-radius);

    cursor: pointer;
    text-align: center;

    background: transparent;
    text-transform: uppercase;

    color: var(--accent5);
    border-radius: var(--border-radius);
    transition: var(--transition);
    background: var(--title-gradient);
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: 1px solid var(--accent1);
}

.settings_container .save_button:hover {
    color: var(--text3);
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
}

/* NFT MODAL------------------------------------*/

.nft_modal_container {
    width: 338px;
    height: 276px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.nft_modal_container h1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text2);
}

.nft_modal_container::-webkit-scrollbar {
    display: none;
}

/* END OF NFT MODAL------------------------------------*/
@media (min-width: 940px) {
    .container {
        height: calc(100vh - 5.5rem);
        width: 100%;
    }
    .content {
        width: 100%;
        /* height: calc(100vh - 5.5rem); */
    }
}
