.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--text1);
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    font-weight: 300;
    letter-spacing: -0.02em;
}

.settings_icon {
    cursor: pointer;
}

.token_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
}

.hoverable_icon {
    fill: var(--text2) !important;
    color: var(--text2) !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hoverable_icon:hover {
    fill: var(--accent1) !important;
}
