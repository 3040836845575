.pagination_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagination_inside_container {
    /* width: 600px; */
    background: var(--dark2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* transition: all 500ms ease-in-out; */
}

.expanded {
    width: 600px;
    /* transition: all 500ms ease-in-out; */
}

.not_expanded {
    width: 100px;
    /* transition: all 500ms ease-in-out; */
}

.pagination_content {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    max-width: 400px;
    overflow-x: scroll;
    border-radius: var(--border-radius);
    scroll-behavior: smooth;
}

.pagination_content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.pagination_content li {
    text-decoration: none;
    list-style: none;
}

.pagination_content button {
    outline: none;
    border: none;
    background: transparent;
    color: var(--text2);

    width: 40px;
    height: 40px;
}

.dot {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page_active button {
    color: var(--text1);
    cursor: pointer;
    /* background: var(--dark1); */
    text-shadow: 0px 0px 10px rgba(254, 255, 254, 0.75);
    background: var(--dark2);
}

.page button {
    cursor: pointer;
}

.page button:hover {
    background: var(--dark1);
    color: var(--text1);
    transition: all 500ms ease-in-out;
}

.scroll_button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 35px 20px rgba(0, 0, 0, 0.3);

    background: var(--dark2);
}

.scroll_button svg {
    color: var(--text3);
}

.scroll_button svg:hover {
    color: var(--text1);
}

.text_info {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}
