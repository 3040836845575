.token_info {
    display: grid;
    grid-template-columns: minmax(18ch, 2fr) 3fr;
    width: 100%;
    align-items: center;
    color: var(--text1);
}

.token_icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}

.exchange_row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    height: 48px;
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
    padding: 0 4px;
    border-radius: var(--border-radius);
}

.exchange_row:hover {
    transition: var(--transition);
    background: var(--dark2);
    cursor: default;
}

.token_key,
.amount {
    font-weight: 300;
    font-size: var(--header2-size);
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--text1);
}

.value,
.amount {
    display: flex;
    justify-content: flex-end;
    color: var(--text1);
}
