@import url(../css/reset.css);
@import url('../css/GlobalCssClassnames.css');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
    font-weight: 300;

    /* outline: 1px solid red; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar-track {
    background: transparent; /* opacity: 0; should do the thing either */
}
body::-webkit-scrollbar-thumb {
    background: linear-gradient(
        0deg,
        var(--accent1) 0%,
        var(--accent5) 49.48%,
        var(--accent1) 100%
    );

    box-shadow: 0px 0px 0px 100000vh black;
}
body {
    background: var(--dark1);
}

html {
    background: var(--dark1);
    scrollbar-width: none;
    color: var(--text1);
    height: 100%;
}

svg {
    color: var(--text2);
    /* transition: var(--transition); */
}

svg:hover {
    color: var(--accent1);
    cursor: pointer;
}

button:hover > svg {
    color: var(--accent1);
    cursor: pointer;
}

button:focus > svg {
    color: var(--accent1);
    cursor: pointer;
}

/* button,
svg,
a {
    transition: var(--transition);
} */

a {
    color: unset;
    text-decoration: none;
    /* position: relative;  */
}

input:disabled {
    color: var(--text3);
    cursor: default;
}

body {
    overflow-x: hidden;

    min-height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    /* padding: 0 1rem; */
}

footer {
    /* To make page 100vh, turn off the footer position sticky and turn on absolute then style the footer based on route. */
    /* position: absolute;
  position: sticky;
  right: 0;
 bottom: 0; */
    margin-top: auto;
}

.swap-body {
    height: calc(100vh - (var(--footer-height) + 2rem));
}

h3:focus-visible,
h2:focus-visible,
h1:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible {
    text-decoration: underline;
    border: none;
    outline: none;
    text-decoration-color: var(--text1);
}
.footer_container {
    color: white;

    position: fixed;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    right: 0;
    bottom: 0px;
    z-index: 999;
}

.primary_apr {
    background: var(--apr-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* glow/positive */

    text-shadow: 0px 0px 10px rgba(21, 190, 111, 0.75);
}

.secondary_apr {
    background: var(--apr-gradient-secondary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.sr-only {
    position: absolute;
    width: 1px;
    aspect-ratio: 1;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.blur_app {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;

    background: rgba(1, 0, 16, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    transition: var(--transition);
}
/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--animation-speed) ease;
}

.menu-secondary-enter {
    position: absolute;
    transform: translateX(110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-secondary-exit {
    position: absolute;
}
.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--animation-speed) ease;
}

.menu-tertiary-enter {
    position: absolute;
    transform: translateX(110%);
}
.menu-tertiary-enter-active {
    transform: translateX(0%);
    transition: all var(--animation-speed) ease;
}
.menu-tertiary-exit {
    position: absolute;
}
.menu-tertiary-exit-active {
    transform: translateX(110%);
    transition: all var(--animation-speed) ease;
}

.hide_sidebar {
    display: grid;
    grid-template-columns: 100%;
}

.tutorial_navigation_container {
    position: absolute;
    bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {
    .app_blur {
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(10px);

        position: fixed;
        top: 56px;
        bottom: 64px;
        z-index: 2;
    }
    .swap-body {
        /* max-height: 86.5vh; */
        overflow-y: hidden;

        max-height: calc(100vh - 7.5rem);
    }
}

@media only screen and (min-width: 1280px) {
    .sidebar_content_layout {
        transition: var(--transition);
        display: grid;
        grid-template-columns: 296px auto;
        overflow-y: hidden;
    }
    .sidebar_content_layout_close {
        transition: var(--transition);

        display: grid;
        grid-template-columns: 32px auto;
        overflow-y: hidden;
    }

    .content-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;

        background: var(--dark1);
    }
    .content-container-trade {
        overflow: hidden;

        background: var(--dark1);
    }
}
