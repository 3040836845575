/* price info */

.price_info_container {
    display: flex;
    flex-direction: column;
    transition: var(--transition);
}

.price_info_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    gap: 4px;
}

.price_display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.price_title,
.min_price,
.current_price,
.max_price,
.apr_price {
    color: var(--text1);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}

.min_price,
.current_price,
.max_price {
    color: var(--text2);
}

.apr_price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    color: var(--other-green);
}

/* .min_price {
    color: var(--accent5);
}

.current_price {
    color: var(--text1);
    cursor: pointer;
}

.max_price {
    color: var(--accent1);
} */

.collateral_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 2rem;
}

.collateral_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.collateral_title {
    color: var(--text2);
    font-size: var(--body-size);
    line-height: var(--body-lh);
}
.collateral_amount {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text1);
    /* font-weight: 400; */
    margin: 5px 0;
}
