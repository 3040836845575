.container {
    width: 100%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}

.container h2 {
    font-size: var(--header2-size);
    line-height: var(--header2-lh);
    color: var(--text2);
}
.container p {
    font-size: var(--body-size);
    line-height: var(--body-lh);
    color: var(--text2);
}

.container button {
    outline: none;
    border: none;
    background: var(--accent1);
    color: var(--text1);
    padding: 4px;
    border-radius: var(--border-radius);
    cursor: pointer;
    text-transform: capitalize;
    transition: var(--transition);
}
.container button:hover {
    background: var(--accent5);
    color: var(--text3);
}
